import axios from 'axios'
import { auth } from 'plugins/firebase'

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

authAxios.interceptors.request.use(async config => {
  // トークンをヘッダーに設定
  const token = await auth.currentUser.getIdToken()
  config.headers.common.Authorization = `Bearer ${token}`
  return config
})

export default authAxios
