import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'consts/theme'

const TabSwitcher = ({ active, values, names, onChange, children }) => {
  const main = useMemo(() => {
    return children(active)
  }, [active, children])

  const onTabClicked = tab => {
    if (active !== tab) {
      onChange(tab)
    }
  }

  return (
    <Container>
      <TabsWrapper>
        {values.map((tab, ind) => (
          <Tab
            isActive={active === tab}
            onClick={() => onTabClicked(tab)}
            key={tab}
          >
            {names[ind]}
          </Tab>
        ))}
      </TabsWrapper>

      <MainWrapper>{main}</MainWrapper>
    </Container>
  )
}

const valueType = PropTypes.oneOfType([PropTypes.number, PropTypes.string])

TabSwitcher.propTypes = {
  active: valueType.isRequired,
  values: PropTypes.arrayOf(valueType).isRequired,
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
}

export default TabSwitcher

const Container = styled.div``

const TabsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  padding: 0 1rem;
  border-bottom: 1px solid ${colors.gray[400]};
`

const Tab = styled.div`
  margin-bottom: -1px;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid transparent;
  border-color: ${p => p.isActive && colors.primary[500]};

  cursor: pointer;

  color: ${p => (p.isActive ? colors.primary[600] : colors.text.base)};
  font-size: 1rem;
  font-weight: bold;

  &:not(:first-child) {
    margin-left: 2rem;
  }
`

const MainWrapper = styled.div`
  margin-top: 2rem;
  padding: 0 1rem;
`
