import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

import AppLayout from 'layouts/AppLayout'
import ExportMembers from 'components/export/molecules/ExportMembers'
import ExportCompany from 'components/export/molecules/ExportCompany'
import ExportSite from 'components/export/molecules/ExportSite'
import TabSwitcher from 'components/common/organisms/TabSwitcher'

const Tabs = {
  MEMBERS: 'MEMBERS',
  COMPANY: 'COMPANY',
  SITE: 'SITE',
}
const tabValues = [Tabs.MEMBERS, Tabs.COMPANY, Tabs.SITE]
const tabNames = ['全従業員', '所属ごと', '現場ごと']

const Export = () => {
  const [selectedTab, setSelectedTab] = useState(Tabs.MEMBERS)

  const onTabChange = useCallback(tab => {
    setSelectedTab(tab)
  }, [])

  return (
    <AppLayout title="出面表出力">
      <Container>
        <TabSwitcher
          active={selectedTab}
          values={tabValues}
          names={tabNames}
          onChange={onTabChange}
        >
          {tab =>
            tab === Tabs.MEMBERS ? (
              <ExportMembers />
            ) : tab === Tabs.COMPANY ? (
              <ExportCompany />
            ) : tab === Tabs.SITE ? (
              <ExportSite />
            ) : (
              <>？</>
            )
          }
        </TabSwitcher>
      </Container>
    </AppLayout>
  )
}

export default Export

const Container = styled.div`
  width: calc(100% + 1rem);
  margin-left: -1rem;
  padding-bottom: 2rem;
`
