import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'

import useAsync from 'hooks/useAsync'
import useModal from 'hooks/useModal'
import useNotification, { notificationTypes } from 'hooks/useNotification'
import DataTableTypes from 'consts/data-table-types'
import { userRoleNames, userRoles } from 'consts/user'
import { getUsers } from 'utils/api'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppLayout from 'layouts/AppLayout'
import AddUserModal from 'components/users/molecules/AddUserModal'
import EditUserModal from 'components/users/molecules/EditUserModal'
import ResetPasswordModal from 'components/users/molecules/ResetPasswordModal'
import DeleteUserModal from 'components/users/molecules/DeleteUserModal'
import DataTable from 'components/common/molecules/DataTable'
import RaisedButton from 'components/common/atoms/RaisedButton'
import ToggleButton from 'components/common/atoms/ToggleButton'
import IconButton from 'components/common/atoms/IconButton'
import SearchBox from 'components/common/atoms/SearchBox'

const columns = ['役職', 'ユーザー名', 'メールアドレス', 'ユーザーID', '']
const keys = ['role', 'name', 'email', 'id', 'actions']
const types = [
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.NODE_RIGHT,
]
const widths = ['6rem', '2fr', '3fr', '2fr', '128px']

const Users = () => {
  const [isFilterShown, setIsFilterShown] = useState(false)
  const [filters, setFilters] = useState({ name: '' })
  const { pending, value: users, error, execute: loadAgain } = useAsync(
    getUsers
  )
  const [openModal, closeModal] = useModal()
  const showNotification = useNotification()

  // エラーハンドリング
  useEffect(() => {
    if (error == null) {
      return
    }

    showNotification(
      notificationTypes.ERROR,
      'エラーが発生しました',
      `データの取得中にエラーが発生しました。時間をおいて、再度お試しください。${error.message}`
    )

    console.error(error)
  }, [error, showNotification])

  const onAddClicked = useCallback(() => {
    openModal(<AddUserModal onAdd={loadAgain} onClose={closeModal} />, {
      persistent: true,
    })
  }, [closeModal, loadAgain, openModal])

  const onEditClicked = useCallback(
    id => {
      openModal(
        <EditUserModal
          user={users[id]}
          onEdit={loadAgain}
          onClose={closeModal}
        />,
        { persistent: true }
      )
    },
    [closeModal, loadAgain, openModal, users]
  )

  const onResetPasswordClicked = useCallback(
    id => {
      openModal(<ResetPasswordModal user={users[id]} onClose={closeModal} />, {
        persistent: true,
      })
    },
    [closeModal, openModal, users]
  )

  const onDeleteClicked = useCallback(
    id => {
      openModal(
        <DeleteUserModal
          user={users[id]}
          onDelete={loadAgain}
          onClose={closeModal}
        />
      )
    },
    [closeModal, loadAgain, openModal, users]
  )

  // 表に表示するデータに整形
  const data = useMemo(() => {
    return (
      Object.values(users ?? {})
        .filter(v => {
          // ユーザー名フィルタ
          if (!v.name.includes(filters.name)) {
            return false
          }

          return true
        })
        .sort((a, b) => {
          // 役職でソート
          if (a.role === userRoles.ADMIN && b.role !== userRoles.ADMIN) {
            return -1
          } else if (b.role === userRoles.ADMIN && a.role !== userRoles.ADMIN) {
            return 1
          } else if (
            a.role === userRoles.SCHEDULER &&
            b.role !== userRoles.SCHEDULER
          ) {
            return -1
          } else if (
            b.role === userRoles.SCHEDULER &&
            a.role !== userRoles.SCHEDULER
          ) {
            return 1
          } else if (a.name < b.name) {
            return -1
          } else if (a.name > b.name) {
            return 1
          }

          return 0
        })
        .map(v => {
          return {
            id: v.id,
            name: v.name,
            email: v.email,
            role: userRoleNames[v.role],
            actions: [
              <IconButton
                onClick={() => {
                  onEditClicked(v.id)
                }}
                key="edit"
              >
                <FontAwesomeIcon icon={['far', 'pen']} />
              </IconButton>,
              <IconButton
                onClick={() => {
                  onResetPasswordClicked(v.id)
                }}
                key="password"
              >
                <FontAwesomeIcon icon={['far', 'key']} />
              </IconButton>,
              <IconButton
                onClick={() => {
                  onDeleteClicked(v.id)
                }}
                disabled={v.role === userRoles.ADMIN}
                key="delete"
              >
                <FontAwesomeIcon icon={['far', 'trash']} />
              </IconButton>,
            ],
          }
        }) ?? []
    )
  }, [
    filters.name,
    onDeleteClicked,
    onEditClicked,
    onResetPasswordClicked,
    users,
  ])

  return (
    <AppLayout title="ユーザー管理">
      <Container>
        <ActionWrapper>
          <ToggleButton
            icon={<FontAwesomeIcon icon={['far', 'sliders-v']} />}
            value={isFilterShown}
            onChange={e => setIsFilterShown(e)}
          >
            フィルター
          </ToggleButton>
          <RaisedButton onClick={onAddClicked} long>
            作成
          </RaisedButton>
        </ActionWrapper>

        <FilterWrapper shown={isFilterShown}>
          <SearchBox
            placeholder="ユーザー名で絞り込む"
            value={filters.name}
            onChange={e => setFilters({ ...filters, name: e.target.value })}
          />
        </FilterWrapper>

        <StyledDataTable
          columns={columns}
          keys={keys}
          data={data}
          types={types}
          widths={widths}
          isLoading={pending}
        />
      </Container>
    </AppLayout>
  )
}

export default Users

const Container = styled.div`
  width: 100%;
  padding: 0 2rem 2rem 0;
`

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FilterWrapper = styled.div`
  display: ${p => (p.shown ? 'flex' : 'none')};
  align-items: center;

  margin-top: 2rem;
`

const StyledDataTable = styled(DataTable)`
  margin-top: 2rem;
`
