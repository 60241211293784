import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'

import useAsync from 'hooks/useAsync'
import useModal from 'hooks/useModal'
import useNotification, { notificationTypes } from 'hooks/useNotification'
import DataTableTypes from 'consts/data-table-types'
import { getClients } from 'utils/api'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppLayout from 'layouts/AppLayout'
import AddClientModal from 'components/clients/molecules/AddClientModal'
import EditClientModal from 'components/clients/molecules/EditClientModal'
import DeleteClientModal from 'components/clients/molecules/DeleteClientModal'
import DataTable from 'components/common/molecules/DataTable'
import RaisedButton from 'components/common/atoms/RaisedButton'
import ToggleButton from 'components/common/atoms/ToggleButton'
import IconButton from 'components/common/atoms/IconButton'
import SearchBox from 'components/common/atoms/SearchBox'

const columns = ['企業名', '略称', '本社住所', '電話番号', 'FAX', '']
const keys = ['name', 'shortName', 'address', 'tel', 'fax', 'actions']
const types = [
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.NODE_RIGHT,
]
const widths = ['3fr', '1fr', '3fr', '2fr', '2fr', '88px']

const Clients = () => {
  const [isFilterShown, setIsFilterShown] = useState(false)
  const [filters, setFilters] = useState({ name: '', period: 'all' })
  const { pending, value: clients, error, execute: loadAgain } = useAsync(
    getClients
  )
  const [openModal, closeModal] = useModal()
  const showNotification = useNotification()

  // エラーハンドリング
  useEffect(() => {
    if (error == null) return

    showNotification(
      notificationTypes.ERROR,
      'エラーが発生しました',
      `データの取得中にエラーが発生しました。時間をおいて、再度お試しください。${error.message}`
    )

    console.error(error)
  }, [error, showNotification])

  const onAddClicked = useCallback(() => {
    openModal(<AddClientModal onAdd={loadAgain} onClose={closeModal} />, {
      persistent: true,
    })
  }, [closeModal, loadAgain, openModal])

  const onEditClicked = useCallback(
    id => {
      openModal(
        <EditClientModal
          client={clients[id]}
          onEdit={loadAgain}
          onClose={closeModal}
        />,
        { persistent: true }
      )
    },
    [closeModal, loadAgain, openModal, clients]
  )

  const onDeleteClicked = useCallback(
    id => {
      openModal(
        <DeleteClientModal
          client={clients[id]}
          onDelete={loadAgain}
          onClose={closeModal}
        />
      )
    },
    [closeModal, loadAgain, openModal, clients]
  )

  // 表に表示するデータに整形
  const data = useMemo(() => {
    return (
      Object.values(clients ?? {})
        .filter(v => {
          // 企業名フィルタ
          if (
            !v.name.includes(filters.name) &&
            !v.shortName.includes(filters.name)
          ) {
            return false
          }

          return true
        })
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1
          } else if (a.name > b.name) {
            return 1
          }
          return 0
        })
        .map(v => {
          return {
            id: v.id,
            name: v.name,
            shortName: v.shortName,
            address: v.address,
            tel: v.tel,
            fax: v.fax,
            actions: [
              <IconButton
                onClick={() => {
                  onEditClicked(v.id)
                }}
                key="edit"
              >
                <FontAwesomeIcon icon={['far', 'pen']} />
              </IconButton>,
              <IconButton
                onClick={() => {
                  onDeleteClicked(v.id)
                }}
                key="delete"
              >
                <FontAwesomeIcon icon={['far', 'trash']} />
              </IconButton>,
            ],
          }
        }) ?? []
    )
  }, [filters, onDeleteClicked, onEditClicked, clients])

  return (
    <AppLayout title="取引先企業">
      <Container>
        <ActionWrapper>
          <ToggleButton
            icon={<FontAwesomeIcon icon={['far', 'sliders-v']} />}
            value={isFilterShown}
            onChange={e => setIsFilterShown(e)}
          >
            フィルター
          </ToggleButton>
          <RaisedButton onClick={onAddClicked} long>
            追加
          </RaisedButton>
        </ActionWrapper>

        <FilterWrapper shown={isFilterShown}>
          <SearchBox
            placeholder="企業名で絞り込む"
            value={filters.name}
            onChange={e => setFilters({ ...filters, name: e.target.value })}
          />
        </FilterWrapper>

        <StyledDataTable
          columns={columns}
          keys={keys}
          data={data}
          types={types}
          widths={widths}
          isLoading={pending}
        />
      </Container>
    </AppLayout>
  )
}

export default Clients

const Container = styled.div`
  width: 100%;
  padding: 0 2rem 2rem 0;
`

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FilterWrapper = styled.div`
  display: ${p => (p.shown ? 'flex' : 'none')};
  align-items: center;

  margin-top: 2rem;
`

const StyledDataTable = styled(DataTable)`
  margin-top: 2rem;
`
