import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/functions'

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
})

export const auth = firebase.auth()
export const Persistence = firebase.auth.Auth.Persistence

export const storage = firebase.storage()
export const TaskEvent = firebase.storage.TaskEvent

export const functions = firebase.app().functions('asia-northeast1')
