import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import moment from 'moment'

import useNotification, { notificationTypes } from 'hooks/useNotification'
import Report, { Reasons } from 'models/Report'
import WorkMember from 'models/WorkMember'
import { colors } from 'consts/theme'
import * as Actions from 'redux/actions/work-actions'
import { isInRange, isStep } from 'consts/rules'

import DateTitle from 'components/common/atoms/DateTitle'
import RaisedButton from 'components/common/atoms/RaisedButton'
import FlatButton from 'components/common/atoms/FlatButton'
import TextArea from 'components/common/atoms/TextArea'
import NumberInput from 'components/common/atoms/NumberInput'
import Dropdown, { dropdownTypes } from 'components/common/atoms/Dropdown'
import Checkbox from 'components/common/atoms/Checkbox'

const manHourRules = [isInRange(0, 1), isStep(0.25)]
const overtimeRules = [isInRange(0, 24)]
const reasonValues = [Reasons.NONE, Reasons.EXAM, Reasons.PAID]
const reasonItems = ['なし', '試験', '有給']

const ReportModal = ({
  member,
  date,
  onClose,
  members,
  setReport,
  deleteReport,
}) => {
  const [input, setInput] = useState(member.reports[date] ?? new Report({}))
  const showNotification = useNotification()

  const onChange = e => {
    const name = e.target.name
    let value = e.target.value

    if (
      name === 'manHour' ||
      name === 'overtime' ||
      name === 'midnightOvertime' ||
      name === 'nightManHour'
    ) {
      value = Number(value)
    }

    setInput({
      ...input,
      [name]: value,
    })
  }

  const onSubmit = e => {
    e.preventDefault()

    if (input.nightManHour > input.manHour) {
      showNotification(
        notificationTypes.ERROR,
        '不正な深夜残業',
        '深夜残業は工数以下である必要があります。'
      )
      return
    }

    if (
      input.manHour !== 1 &&
      input.reason !== null &&
      input.reason !== 'NONE'
    ) {
      showNotification(
        notificationTypes.ERROR,
        '不正な特別理由',
        '特別理由を設定するには工数が1である必要があります。'
      )
      return
    }

    // 特別理由はNONEではなくnullにする
    if (input.reason === 'NONE') {
      input.reason = null
    }

    setReport(member.id, date, new Report({ ...input, isReported: true }))
    onClose()
  }

  const handleDeleteReport = () => {
    deleteReport(member.id, date, input.schedule)
    onClose()
  }

  return (
    <Form onSubmit={onSubmit}>
      <AvatorAndName>
        {members[member.id]?.faceImg ? (
          <Avator src={members[member.id].faceImg} />
        ) : (
          <DummyAvator />
        )}
        <Name>{members[member.id].name}</Name>
      </AvatorAndName>

      <DateTitle date={moment(date)} small />

      <InputSection>
        <InputWrapper>
          <NumberInput
            label="作業工数"
            name="manHour"
            value={input.manHour}
            onChange={onChange}
            rules={manHourRules}
          />
          <Unit>工数</Unit>
        </InputWrapper>
      </InputSection>

      <InputSection>
        <InputWrapper>
          <NumberInput
            label="残業時間"
            name="overtime"
            value={input.overtime}
            onChange={onChange}
            rules={overtimeRules}
          />
          <Unit>時間</Unit>
        </InputWrapper>
      </InputSection>

      <InputSection>
        <InputWrapper>
          <NumberInput
            label="深夜残業時間"
            name="midnightOvertime"
            value={input.midnightOvertime}
            onChange={onChange}
            rules={overtimeRules}
          />
          <Unit>時間</Unit>
        </InputWrapper>
      </InputSection>

      <InputSection>
        <InputWrapper>
          <NumberInput
            label="深夜残業"
            name="nightManHour"
            value={input.nightManHour}
            onChange={onChange}
            rules={manHourRules}
          />
          <Unit>工数</Unit>
        </InputWrapper>
      </InputSection>

      <InputSection>
        <InputTitle>特別理由</InputTitle>
        <Dropdown
          selected={input.reason ?? Reasons.NONE}
          values={reasonValues}
          items={reasonItems}
          onChange={v => setInput({ ...input, reason: v })}
          type={dropdownTypes.INPUT_LIKE}
        />
      </InputSection>

      <InputSection>
        <InputWrapper>
          <CheckboxWrapper>
            <Checkbox
              value={input.meal}
              onChange={e => setInput({ ...input, meal: e.target.checked })}
            />
            <CheckboxLabel>弁当支給</CheckboxLabel>
          </CheckboxWrapper>
        </InputWrapper>
      </InputSection>

      <InputSection>
        <TextArea
          label="作業内容"
          rows={3}
          name="detail"
          value={input.detail}
          onChange={onChange}
          fullWidth
        />
      </InputSection>

      <InputSection>
        <TextArea
          label="摘要"
          rows={3}
          name="summary"
          value={input.summary}
          onChange={onChange}
          fullWidth
        />
      </InputSection>

      <Trailing>
        <TrailingLeft>
          <RaisedButton
            type="button"
            color={colors.red[500]}
            onClick={handleDeleteReport}
            long
          >
            削除
          </RaisedButton>
        </TrailingLeft>
        <TrailingRight>
          <FlatButton type="button" onClick={onClose}>
            キャンセル
          </FlatButton>
          <RaisedButton type="submit" color={colors.primary[500]} long>
            保存
          </RaisedButton>
        </TrailingRight>
      </Trailing>
    </Form>
  )
}

ReportModal.propTypes = {
  member: PropTypes.instanceOf(WorkMember).isRequired,
  date: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default connect(
  state => ({
    members: state.work.members,
  }),
  dispatch => ({
    setReport(memberId, date, report) {
      dispatch(Actions.setReport(memberId, date, report))
    },
    deleteReport(memberId, date, schedule) {
      dispatch(Actions.deleteReport(memberId, date, schedule))
    },
  })
)(ReportModal)

const Form = styled.form`
  min-width: 40rem;
`

const AvatorAndName = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 2rem;
`

const Avator = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
`

const DummyAvator = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: ${colors.gray[300]};
`

const Name = styled.h1`
  margin-left: 2rem;

  color: ${colors.text.dark};
  font-size: 2rem;
  font-weight: bold;
`

const InputSection = styled.div`
  margin-top: 2rem;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`

const InputTitle = styled.h3`
  margin-bottom: 0.5rem;

  color: ${colors.text.base};
  font-size: 1rem;
  font-weight: 500;
`

const Unit = styled.span`
  margin-left: 0.75rem;

  color: ${colors.text.base};
  font-size: 1rem;
  font-weight: 500;
`

const CheckboxWrapper = styled.label`
  display: inline-flex;
  align-items: center;

  cursor: pointer;
`

const CheckboxLabel = styled.span`
  margin-left: 0.75rem;

  color: ${colors.text.base};
  font-size: 1rem;
  font-weight: 500;
`

const Trailing = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 2rem;
`

const TrailingLeft = styled.div`
  display: flex;
  justify-content: flex-start;
`

const TrailingRight = styled.div`
  display: flex;
  justify-content: flex-end;

  *:not(:first-child) {
    margin-left: 1rem;
  }
`
