import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'

import useRouter from 'hooks/useRouter'
import useModal from 'hooks/useModal'
import useNotification, { notificationTypes } from 'hooks/useNotification'
import * as Actions from 'redux/actions/work-actions'

import AppLayout from 'layouts/AppLayout'
import WorkGrid from 'components/work/organisms/WorkGrid'
import AddWorkMemberModal from 'components/work/molecules/AddWorkMemberModal'
import SegmentedControl from 'components/common/atoms/SegmentedControl'
import RaisedButton from 'components/common/atoms/RaisedButton'

const PERIOD_NAMES = ['一週', '二週']
const PERIOD_DAYS = [7, 14]

const Work = ({
  work,
  period,
  reset,
  members,
  fetchMembers,
  fetchWork,
  setPeriod,
}) => {
  const { query } = useRouter()
  const [openModal, closeModal] = useModal()
  const showNotification = useNotification()

  /**
   * 初期処理
   */
  useEffect(() => {
    reset()
    ;(async () => {
      try {
        await fetchMembers()
        await fetchWork(query.siteId, moment().startOf('day'))
      } catch (err) {
        showNotification(
          notificationTypes.ERROR,
          'エラーが発生しました',
          `データの取得中にエラーが発生しました。時間をおいて、再度お試しください。${err.message}`
        )

        console.error(err)
      }
    })()
  }, [fetchMembers, fetchWork, query.siteId, reset, showNotification])

  const onPeriodChange = useCallback(
    ind => {
      setPeriod(PERIOD_DAYS[ind])
    },
    [setPeriod]
  )

  const onAddClicked = useCallback(() => {
    openModal(<AddWorkMemberModal onClose={closeModal} />, {
      persistent: true,
    })
  }, [closeModal, openModal])

  return (
    <AppLayout
      title={work == null ? '読込中' : `${work.name}（${work.clientName}）`}
    >
      <Container>
        <ActionWrapper>
          <SegmentedControl
            items={PERIOD_NAMES}
            selected={PERIOD_DAYS.findIndex(p => p === period)}
            onChange={onPeriodChange}
          />
          <RaisedButton onClick={onAddClicked} loading={!members || !work}>
            従業員を追加
          </RaisedButton>
        </ActionWrapper>

        <WorkGrid />
      </Container>
    </AppLayout>
  )
}

export default connect(
  state => ({
    work: state.work.work,
    period: state.work.period,
    members: state.work.members,
  }),
  dispatch => ({
    reset() {
      dispatch(Actions.reset())
    },
    fetchMembers() {
      return dispatch(Actions.fetchMembers())
    },
    fetchWork(siteId, to) {
      return dispatch(Actions.fetchWork(siteId, to))
    },
    setPeriod(days) {
      return dispatch(Actions.setPeriod(days))
    },
  })
)(Work)

const Container = styled.div`
  width: 100%;
  padding-right: 4rem;
`

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
