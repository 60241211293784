import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import useNotification, { notificationTypes } from 'hooks/useNotification'
import { colors } from 'consts/theme'
import DataTableTypes from 'consts/data-table-types'
import useAsync from 'hooks/useAsync'
import { getCompanies, exportCompany } from 'utils/api'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchBox from 'components/common/atoms/SearchBox'
import DataTable from 'components/common/molecules/DataTable'
import IconButton from 'components/common/atoms/IconButton'
import ValidatableForm from 'components/common/atoms/ValidatableForm'
import TextField from 'components/common/atoms/TextField'

const MONTH_RULES = [
  v => {
    if (/^\d{4}-\d{2}$/.test(v)) {
      return true
    }
    return 'YYYY-MM の形式で入力してください'
  },
]
const columns = ['企業名', '略称', '']
const keys = ['name', 'shortName', 'actions']
const types = [
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.NODE_RIGHT,
]
const widths = ['3fr', '1fr', '48px']

const ExportMembers = () => {
  const [monthInput, setMonthInput] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [loadingId, setLoadingId] = useState(null)
  const [search, setSearch] = useState('')
  const { pending, value: companies, error } = useAsync(getCompanies)
  const showNotification = useNotification()

  // エラーハンドリング
  useEffect(() => {
    if (error == null) return

    showNotification(
      notificationTypes.ERROR,
      'エラーが発生しました',
      `所属情報の取得中にエラーが発生しました。時間をおいて、再度お試しください。${error.message}`
    )
  }, [error, showNotification])

  const onSearchChanged = useCallback(e => {
    setSearch(e.target.value)
  }, [])

  const onExportClicked = useCallback(
    async companyId => {
      if (!moment(monthInput).isValid()) {
        showNotification(
          notificationTypes.ERROR,
          '不正な出力月',
          '正しい形式で出力月を入力してください。'
        )
        return
      }

      setLoadingId(companyId)

      try {
        const res = await exportCompany(monthInput, companyId)
        const { base64, filename } = res.data
        const binaryStr = atob(base64)
        const u8 = Uint8Array.from(binaryStr.split(''), e => e.charCodeAt(0))
        const blob = new Blob([u8], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        if (window.navigator.msSaveOrOpenBlob) {
          // IE, Edge向け
          window.navigator.msSaveOrOpenBlob(blob, filename)
        } else {
          const url = URL.createObjectURL(blob)
          const el = document.createElement('a')

          // ダウンロードを実行
          el.href = url
          el.setAttribute('download', filename)
          document.body.appendChild(el)
          el.click()

          // インスタンスとDOMエレメントを開放
          URL.revokeObjectURL(url)
          el.parentNode.removeChild(el)
        }
      } catch (error) {
        showNotification(
          notificationTypes.ERROR,
          'エラーが発生しました',
          `出面表の出力中にエラーが発生しました。時間をおいて、再度お試しください。${error.message}`
        )
      } finally {
        setLoadingId(null)
      }
    },
    [monthInput, showNotification]
  )

  const onChange = useCallback(e => {
    setMonthInput(e.target.value)
  }, [])

  // 表に表示するデータに整形
  const data = useMemo(() => {
    return (
      Object.values(companies ?? {})
        .filter(v => {
          // 企業名フィルタ
          if (!v.name.includes(search)) {
            return false
          }

          return true
        })
        .map(v => {
          return {
            id: v.id,
            name: v.name,
            shortName: v.shortName,
            actions: [
              <IconButton
                onClick={() => {
                  onExportClicked(v.id)
                }}
                disabled={!isValid || loadingId === v.id}
                key="export"
              >
                <FontAwesomeIcon icon={['far', 'download']} />
              </IconButton>,
            ],
          }
        }) ?? []
    )
  }, [companies, search, loadingId, isValid, onExportClicked])

  return (
    <>
      <Description>
        所属企業ごとにまとめた出面表を Excel 形式で出力します。
      </Description>

      <ValidatableForm
        onSubmit={e => e.preventDefault()}
        onValidated={isValid => setIsValid(isValid)}
      >
        <StyledTextField
          value={monthInput}
          onChange={onChange}
          label="出力月"
          rules={MONTH_RULES}
        />
      </ValidatableForm>

      <FilterWrapper>
        <SearchBox
          value={search}
          onChange={onSearchChanged}
          placeholder="グループ企業名で絞り込む"
        />
      </FilterWrapper>

      <StyledDataTable
        columns={columns}
        keys={keys}
        data={data}
        types={types}
        widths={widths}
        isLoading={pending}
      />
    </>
  )
}

export default ExportMembers

const Description = styled.p`
  margin-bottom: 1.5rem;

  color: ${colors.text.base};
`

const StyledTextField = styled(TextField)`
  width: 20rem;
  margin-bottom: 1.5rem;
`

const FilterWrapper = styled.div`
  width: 20rem;
`

const StyledDataTable = styled(DataTable)`
  margin-top: 1.5rem;
`
