import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import * as Actions from 'redux/actions/schedule-actions'

import DroppableArea from './DroppableArea'

const AREA_WIDTH = 16 // rem
const KANBAN_HEIGHT = 5.5 // rem

const UnallocatedArea = ({
  members,
  unallocated,
  allocatedInLastSomeDays,
  moveMember,
}) => {
  const [areaHeight, setAreaHeight] = useState('0rem')

  useEffect(() => {
    setAreaHeight(`${KANBAN_HEIGHT * unallocated.length}rem`)
  }, [unallocated])

  /**
   * 頻繁に場割されている人の方が数値が大きくなるような関数
   */
  const calcFrequency = x => {
    const coefficient = 1 / allocatedInLastSomeDays.length
    let sum = 0

    allocatedInLastSomeDays.forEach((ids, ind) => {
      if (ids.includes(x.id)) {
        sum += 1 - coefficient * ind
      }
    })

    return sum
  }

  /**
   * unallocatedにはScheduleMemberインスタンス配列が入っているため、
   * それらをMemberインスタンスの配列に変換する
   */
  const unallocatedMembers = unallocated
    .map(mem => members[mem.id])
    .sort((a, b) => {
      // 1. 頻繁に場割されている人が上
      const fa = calcFrequency(a)
      const fb = calcFrequency(b)
      if (fa < fb) return 1
      if (fa > fb) return -1

      // 2. 所属順ソート
      if (a.companyShort < b.companyShort) return -1
      if (a.companyShort > b.companyShort) return 1

      // 3. 名前順ソート
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1

      return 0
    })

  const onDrop = item => {
    moveMember(item.id, null, null)
  }

  return (
    <Area>
      <StyledDroppableArea
        name="未割り当て"
        members={unallocatedMembers}
        kanbansWrapperStyle={{ height: areaHeight }}
        onDrop={onDrop}
      />
    </Area>
  )
}

export default connect(
  state => ({
    members: state.schedule.members,
    unallocated: state.schedule.unallocated,
    allocatedInLastSomeDays: state.schedule.allocatedInLastSomeDays,
  }),
  dispatch => ({
    moveMember(memberId, siteId, timeDivision) {
      dispatch(Actions.moveMember(memberId, siteId, timeDivision))
    },
  })
)(UnallocatedArea)

const Area = styled.div`
  flex: 0 0 auto;
  position: sticky;
  top: 0;

  width: ${AREA_WIDTH}rem;
  height: 100%;
  max-height: 100vh;
  margin-left: 1.5rem;
  border-top-left-radius: 1rem;

  overflow: hidden;
`

const StyledDroppableArea = styled(DroppableArea)`
  width: 100%;
  height: 100%;

  overflow-y: auto;
`
