import React, { useEffect, useContext } from 'react'

import { auth } from 'plugins/firebase'
import UserContext from 'contexts/User'
import useRouter from 'hooks/useRouter'

const Logout = () => {
  const ctx = useContext(UserContext)
  const router = useRouter()

  useEffect(() => {
    auth.signOut().then(() => {
      ctx.logout()
      router.replace('/login')
    })
  }, [ctx, router])

  return <div></div>
}

export default Logout
