import { combineReducers } from 'redux'

import schedule from './schedule-reducer'
import work from './work-reducer'

const reducer = combineReducers({
  schedule,
  work,
})

export default reducer
