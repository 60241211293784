import React from 'react'
import styled from 'styled-components'

import { colors } from 'consts/theme'

import AppLayout from 'layouts/AppLayout'
import RaisedButton from 'components/common/atoms/RaisedButton'

const Logs = () => {
  return (
    <AppLayout title="操作ログ">
      <Description>
        操作ログは Google Cloud Platform の「ロギング」ページにて確認できます。
        <br />
        詳しくはマニュアルを参照してください。
      </Description>
      <RaisedButton href="https://console.cloud.google.com/logs/query">
        Google Cloud Platform を開く
      </RaisedButton>
    </AppLayout>
  )
}

export default Logs

const Description = styled.p`
  margin-bottom: 2rem;

  color: ${colors.text.base};
`
