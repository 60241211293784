import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import useAsync from 'hooks/useAsync'
import useModal from 'hooks/useModal'
import useNotification, { notificationTypes } from 'hooks/useNotification'
import DataTableTypes from 'consts/data-table-types'
import { getMembers, getCompanies, getSchedules } from 'utils/api'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppLayout from 'layouts/AppLayout'
import RaisedButton from 'components/common/atoms/RaisedButton'
import ToggleButton from 'components/common/atoms/ToggleButton'
import IconButton from 'components/common/atoms/IconButton'
import SearchBox from 'components/common/atoms/SearchBox'
import Dropdown from 'components/common/atoms/Dropdown'
import AddMemberModal from 'components/members/molecules/AddMemberModal'
import EditMemberModal from 'components/members/molecules/EditMemberModal'
import ResumeModal from 'components/members/molecules/ResumeModal'
import DeleteMemberModal from 'components/members/molecules/DeleteMemberModal'
import DataTable from 'components/common/molecules/DataTable'

const columns = ['顔写真', '名前', 'ふりがな', '所属', '職種', '年齢', '']
const keys = ['faceImg', 'name', 'ruby', 'company', 'role', 'age', 'actions']
const types = [
  DataTableTypes.AVATAR,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.NUMBER,
  DataTableTypes.NODE_RIGHT,
]
const widths = ['3.5rem', '2fr', '2fr', '1fr', '1fr', '1fr', '128px']

async function getAllocatedIds() {
  // スケジュールを取得
  const mom = moment().add(1, 'day')
  const momSomeDaysAgo = mom.clone().subtract(30, 'day') // 30日前までで計算

  let schedules = {}

  await getSchedules(mom.year(), mom.month() + 1).then(res => {
    schedules = Object.assign(schedules, res)
  })

  if (mom.format('YYYY-MM') !== momSomeDaysAgo.format('YYYY-MM')) {
    await getSchedules(momSomeDaysAgo.year(), momSomeDaysAgo.month() + 1).then(
      res => {
        schedules = Object.assign(schedules, res)
      }
    )
  }

  // 割り当て済みのメンバIDを取得
  const allocatedIds = []

  let date = mom.clone().startOf('day')
  const dateSomeDaysAgo = momSomeDaysAgo.clone().startOf('day')

  while (!date.isSameOrBefore(dateSomeDaysAgo)) {
    date.subtract(1, 'day')

    const schedule = schedules[date.format('YYYY-MM-DD')]
    const sites = schedule?.sites ?? []
    const ids = []

    for (let site of sites) {
      for (let member of site.members) {
        ids.push(member.id)
      }
    }
    allocatedIds.push(ids)
  }

  return allocatedIds
}

const Members = () => {
  const [isFilterShown, setIsFilterShown] = useState(false)
  const [filters, setFilters] = useState({ name: '', company: 'all' })

  const {
    pending: isMembersPending,
    value: members,
    error: membersError,
    execute: loadAgain,
  } = useAsync(getMembers)
  const {
    pending: isCompaniesPending,
    value: companies,
    error: companiesError,
  } = useAsync(getCompanies)
  const {
    pending: isAllocatedIdsPending,
    value: allocatedIds,
    error: allocatedIdsError,
  } = useAsync(getAllocatedIds)
  const [openModal, closeModal] = useModal()
  const showNotification = useNotification()

  // エラーハンドリング
  useEffect(() => {
    if (membersError == null) {
      return
    }

    showNotification(
      notificationTypes.ERROR,
      'エラーが発生しました',
      `従業員データの取得中にエラーが発生しました。時間をおいて、再度お試しください。${membersError.message}`
    )

    console.error(membersError)
  }, [membersError, showNotification])
  useEffect(() => {
    if (companiesError == null) {
      return
    }

    showNotification(
      notificationTypes.ERROR,
      'エラーが発生しました',
      `グループ企業データの取得中にエラーが発生しました。時間をおいて、再度お試しください。${companiesError.message}`
    )

    console.error(companiesError)
  }, [companiesError, showNotification])
  useEffect(() => {
    if (allocatedIdsError == null) {
      return
    }

    showNotification(
      notificationTypes.ERROR,
      'エラーが発生しました',
      `割り当て済みIDの取得中にエラーが発生しました。時間をおいて、再度お試しください。${allocatedIdsError.message}`
    )

    console.error(allocatedIdsError)
  }, [allocatedIdsError, showNotification])

  const [companyValues, companyItems] = useMemo(() => {
    const values = ['all']
    const items = ['すべて']

    Object.entries(companies ?? {}).forEach(([id, company]) => {
      values.push(id)
      items.push(company.name)
    })

    return [values, items]
  }, [companies])

  const onAddClicked = useCallback(() => {
    if (isCompaniesPending) return

    openModal(
      <AddMemberModal
        companies={companies}
        onAdd={loadAgain}
        onClose={closeModal}
      />,
      {
        persistent: true,
      }
    )
  }, [closeModal, companies, isCompaniesPending, loadAgain, openModal])

  const onEditClicked = useCallback(
    id => {
      if (isCompaniesPending) return

      openModal(
        <EditMemberModal
          member={members[id]}
          companies={companies}
          onEdit={loadAgain}
          onClose={closeModal}
        />,
        { persistent: true }
      )
    },
    [closeModal, companies, isCompaniesPending, loadAgain, members, openModal]
  )

  const onResumeClicked = useCallback(
    id => {
      if (isCompaniesPending) return

      openModal(
        <ResumeModal
          member={members[id]}
          companies={companies}
          onClose={closeModal}
        />
      )
    },
    [closeModal, companies, isCompaniesPending, members, openModal]
  )

  const onDeleteClicked = useCallback(
    id => {
      openModal(
        <DeleteMemberModal
          member={members[id]}
          onDelete={loadAgain}
          onClose={closeModal}
        />
      )
    },
    [closeModal, loadAgain, members, openModal]
  )

  // 表に表示するデータに整形
  const data = useMemo(() => {
    /**
     * 頻繁に場割されている人の方が数値が大きくなるような関数
     */
    const calcFrequency = x => {
      if (allocatedIds == null) return

      const coefficient = 1 / allocatedIds.length
      let sum = 0

      allocatedIds.forEach((ids, ind) => {
        if (ids.includes(x.id)) {
          sum += 1 - coefficient * ind
        }
      })

      return sum
    }

    return (
      Object.values(members ?? {})
        .filter(v => {
          // 従業員名フィルタ
          if (!v.name.includes(filters.name)) {
            return false
          }

          // 所属企業フィルタ
          if (filters.company !== 'all' && v.company !== filters.company) {
            return false
          }

          return true
        })
        .sort((a, b) => {
          // 1. 頻繁に場割されている人が上
          const fa = calcFrequency(a)
          const fb = calcFrequency(b)
          if (fa < fb) return 1
          if (fa > fb) return -1

          // 2. 所属順ソート
          if (companies?.[a.company]?.name < companies?.[b.company]?.name)
            return -1
          if (companies?.[a.company]?.name > companies?.[b.company]?.name)
            return 1

          // 3. 名前順ソート
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1

          return 0
        })
        .map(v => {
          return {
            id: v.id,
            faceImg: v.faceImg,
            name: v.name,
            ruby: v.ruby,
            company: companies?.[v.company]?.name,
            role: v.role.name,
            age: v.age,
            actions: [
              <IconButton
                onClick={() => {
                  onEditClicked(v.id)
                }}
                disabled={isCompaniesPending}
                key="edit"
              >
                <FontAwesomeIcon icon={['far', 'pen']} />
              </IconButton>,
              <IconButton
                onClick={() => {
                  onResumeClicked(v.id)
                }}
                disabled={isCompaniesPending}
                key="resume"
              >
                <FontAwesomeIcon icon={['far', 'cloud-download-alt']} />
              </IconButton>,
              <IconButton
                onClick={() => {
                  onDeleteClicked(v.id)
                }}
                key="delete"
              >
                <FontAwesomeIcon icon={['far', 'trash']} />
              </IconButton>,
            ],
          }
        }) ?? []
    )
  }, [
    members,
    allocatedIds,
    filters.name,
    filters.company,
    companies,
    isCompaniesPending,
    onEditClicked,
    onResumeClicked,
    onDeleteClicked,
  ])

  return (
    <AppLayout title="従業員">
      <Container>
        <ActionWrapper>
          <ToggleButton
            icon={<FontAwesomeIcon icon={['far', 'sliders-v']} />}
            value={isFilterShown}
            onChange={e => setIsFilterShown(e)}
          >
            フィルター
          </ToggleButton>
          <RaisedButton
            onClick={onAddClicked}
            loading={isCompaniesPending}
            long
          >
            追加
          </RaisedButton>
        </ActionWrapper>

        <FilterWrapper shown={isFilterShown}>
          <SearchBox
            placeholder="従業員名で絞り込む"
            value={filters.name}
            onChange={e => setFilters({ ...filters, name: e.target.value })}
          />
          <StyledDropdown
            selected={filters.company}
            values={companyValues}
            items={companyItems}
            onChange={e => setFilters({ ...filters, company: e })}
          />
        </FilterWrapper>

        <StyledDataTable
          columns={columns}
          keys={keys}
          data={data}
          types={types}
          widths={widths}
          isLoading={
            isMembersPending || isCompaniesPending || isAllocatedIdsPending
          }
        />
      </Container>
    </AppLayout>
  )
}

export default Members

const Container = styled.div`
  width: 100%;
  padding: 0 2rem 2rem 0;
`

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FilterWrapper = styled.div`
  display: ${p => (p.shown ? 'flex' : 'none')};
  align-items: center;

  margin-top: 2rem;
`

const StyledDropdown = styled(Dropdown)`
  margin-left: 1rem;
`

const StyledDataTable = styled(DataTable)`
  margin-top: 2rem;
`
