import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import useNotification, { notificationTypes } from 'hooks/useNotification'
import { colors } from 'consts/theme'
import DataTableTypes from 'consts/data-table-types'
import useAsync from 'hooks/useAsync'
import { getSites, exportSite } from 'utils/api'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchBox from 'components/common/atoms/SearchBox'
import DataTable from 'components/common/molecules/DataTable'
import IconButton from 'components/common/atoms/IconButton'
import ToggleButton from 'components/common/atoms/ToggleButton'
import Dropdown from 'components/common/atoms/Dropdown'
import ValidatableForm from 'components/common/atoms/ValidatableForm'
import TextField from 'components/common/atoms/TextField'

const MONTH_RULES = [
  v => {
    if (/^\d{4}-\d{2}$/.test(v)) {
      return true
    }
    return 'YYYY-MM の形式で入力してください'
  },
]
const columns = [
  '現場名',
  '略称',
  'クライアント',
  '現場住所',
  '開始日',
  '終了日',
  '',
]
const keys = [
  'name',
  'shortName',
  'clientName',
  'address',
  'from',
  'to',
  'actions',
]
const types = [
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.NODE_RIGHT,
]
const widths = ['3fr', '1fr', '2fr', '3fr', '2fr', '2fr', '88px']

const periodDropdownValues = [
  'all',
  'notFinished',
  'beforePeriod',
  'inPeriod',
  'finished',
]
const periodDropdownItems = ['すべて', '未完了', '工期前', '工期中', '完了済み']

const ExportSite = () => {
  const [monthInput, setMonthInput] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [loadingId, setLoadingId] = useState(null)
  const [isFilterShown, setIsFilterShown] = useState(false)
  const [filters, setFilters] = useState({ name: '', period: 'all' })
  const { pending, value: sites, error } = useAsync(getSites)
  const showNotification = useNotification()

  // エラーハンドリング
  useEffect(() => {
    if (error == null) return

    showNotification(
      notificationTypes.ERROR,
      'エラーが発生しました',
      `現場情報の取得中にエラーが発生しました。時間をおいて、再度お試しください。${error.message}`
    )
  }, [error, showNotification])

  const onExportClicked = useCallback(
    async siteId => {
      if (!moment(monthInput).isValid()) {
        showNotification(
          notificationTypes.ERROR,
          '不正な出力月',
          '正しい形式で出力月を入力してください。'
        )
        return
      }

      setLoadingId(siteId)

      try {
        const res = await exportSite(monthInput, siteId)
        const { base64, filename } = res.data
        const binaryStr = atob(base64)
        const u8 = Uint8Array.from(binaryStr.split(''), e => e.charCodeAt(0))
        const blob = new Blob([u8], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        if (window.navigator.msSaveOrOpenBlob) {
          // IE, Edge向け
          window.navigator.msSaveOrOpenBlob(blob, filename)
        } else {
          const url = URL.createObjectURL(blob)
          const el = document.createElement('a')

          // ダウンロードを実行
          el.href = url
          el.setAttribute('download', filename)
          document.body.appendChild(el)
          el.click()

          // インスタンスとDOMエレメントを開放
          URL.revokeObjectURL(url)
          el.parentNode.removeChild(el)
        }
      } catch (error) {
        showNotification(
          notificationTypes.ERROR,
          'エラーが発生しました',
          `出面表の出力中にエラーが発生しました。時間をおいて、再度お試しください。${error.message}`
        )
      } finally {
        setLoadingId(null)
      }
    },
    [monthInput, showNotification]
  )

  const onChange = useCallback(e => {
    setMonthInput(e.target.value)
  }, [])

  // 表に表示するデータに整形
  const data = useMemo(() => {
    const today = moment()

    return (
      Object.values(sites ?? {})
        .filter(v => {
          // 現場名フィルタ
          if (!v.name.includes(filters.name)) {
            return false
          }

          // 工期フィルタ
          if (
            filters.period === 'notFinished' &&
            today.isAfter(v.workPeriod.to)
          ) {
            return false
          } else if (
            filters.period === 'beforePeriod' &&
            today.isSameOrAfter(v.workPeriod.from)
          ) {
            return false
          } else if (
            filters.period === 'inPeriod' &&
            (today.isBefore(v.workPeriod.from) ||
              today.isAfter(v.workPeriod.to))
          ) {
            return false
          } else if (
            filters.period === 'finished' &&
            today.isSameOrBefore(v.workPeriod.to)
          ) {
            return false
          }

          return true
        })
        .map(v => {
          return {
            id: v.id,
            name: v.name,
            shortName: v.shortName,
            clientName: v.clientName,
            address: v.address,
            from: v.workPeriod.from.format('YYYY-MM-DD'),
            to: v.workPeriod.to.format('YYYY-MM-DD'),
            actions: [
              <IconButton
                onClick={() => {
                  onExportClicked(v.id)
                }}
                disabled={!isValid || loadingId === v.id}
                key="export"
              >
                <FontAwesomeIcon icon={['far', 'download']} />
              </IconButton>,
            ],
          }
        }) ?? []
    )
  }, [sites, filters.name, filters.period, loadingId, isValid, onExportClicked])

  return (
    <>
      <Description>
        現場ごとにまとめた出面表を Excel 形式で出力します。
      </Description>

      <ValidatableForm
        onSubmit={e => e.preventDefault()}
        onValidated={isValid => setIsValid(isValid)}
      >
        <StyledTextField
          value={monthInput}
          onChange={onChange}
          label="出力月"
          rules={MONTH_RULES}
        />
      </ValidatableForm>

      <ToggleButton
        icon={<FontAwesomeIcon icon={['far', 'sliders-v']} />}
        value={isFilterShown}
        onChange={e => setIsFilterShown(e)}
      >
        フィルター
      </ToggleButton>

      <FilterWrapper shown={isFilterShown}>
        <SearchBox
          value={filters.name}
          onChange={e => setFilters({ ...filters, name: e.target.value })}
          placeholder="現場名で絞り込む"
        />
        <StyledDropdown
          selected={filters.period}
          values={periodDropdownValues}
          items={periodDropdownItems}
          onChange={e => setFilters({ ...filters, period: e })}
        />
      </FilterWrapper>

      <StyledDataTable
        columns={columns}
        keys={keys}
        data={data}
        types={types}
        widths={widths}
        isLoading={pending}
      />
    </>
  )
}

export default ExportSite

const Description = styled.p`
  margin-bottom: 1.5rem;

  color: ${colors.text.base};
`

const StyledTextField = styled(TextField)`
  width: 20rem;
  margin-bottom: 1.5rem;
`

const FilterWrapper = styled.div`
  display: ${p => (p.shown ? 'flex' : 'none')};
  align-items: center;

  margin-top: 1.5rem;
`

const StyledDropdown = styled(Dropdown)`
  margin-left: 1rem;
`

const StyledDataTable = styled(DataTable)`
  margin-top: 1.5rem;
`
