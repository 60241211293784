import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { isNotEmpty, isNumeric } from 'consts/rules'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextField from 'components/common/atoms/TextField'
import IconButton from 'components/common/atoms/IconButton'
import { colors } from 'consts/theme'

const typeRules = [isNotEmpty()]
const numberRules = [isNotEmpty(), isNumeric()]

const LicencesInput = ({ value, onChange }) => {
  const onAddLicenceClicked = useCallback(() => {
    const newVal = value.map(v => ({ ...v }))
    newVal.push({
      type: '',
      number: '',
    })
    onChange(newVal)
  }, [onChange, value])

  const onLicenceChanged = useCallback(
    (ind, prop, e) => {
      const newVal = value.map(v => ({ ...v }))
      newVal[ind][prop] = e.target.value
      onChange(newVal)
    },
    [onChange, value]
  )

  const onRemoveLicenceClicked = useCallback(
    ind => {
      const newVal = value.map(v => ({ ...v }))
      newVal.splice(ind, 1)
      onChange(newVal)
    },
    [onChange, value]
  )

  return (
    <Container>
      {value.map((licence, ind) => (
        <Licence key={ind}>
          <LicenceTextFieldWrapper>
            <StyledTextField
              label="種別"
              value={licence.type}
              onChange={e => onLicenceChanged(ind, 'type', e)}
              rules={typeRules}
            />
            <StyledTextField
              label="番号"
              value={licence.number}
              onChange={e => onLicenceChanged(ind, 'number', e)}
              rules={numberRules}
            />
          </LicenceTextFieldWrapper>
          <IconButton title="免許証を削除" onClick={onRemoveLicenceClicked}>
            <FontAwesomeIcon icon={['far', 'trash']} />
          </IconButton>
        </Licence>
      ))}
      <AddLicenceButton onClick={onAddLicenceClicked}>
        <AddLicenceIcon icon={['far', 'plus']} />
        <AddLicenceLabel>免許証を追加する</AddLicenceLabel>
      </AddLicenceButton>
    </Container>
  )
}

LicencesInput.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default LicencesInput

const Container = styled.div``

const Licence = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 1rem;

  > *:not(:first-child) {
    margin-left: 1rem;
  }
`

const LicenceTextFieldWrapper = styled.div`
  flex: 1 1 0;
  display: flex;

  > *:not(:first-child) {
    margin-left: 1rem;
  }
`

const StyledTextField = styled(TextField)`
  flex: 1 1 0;
`

const AddLicenceButton = styled.button`
  display: flex;
  align-items: center;

  padding: 0.5rem 1rem;
  border: 1px solid ${colors.gray[600]};
  border-radius: 0.25rem;

  color: ${colors.text.base};
`

const AddLicenceIcon = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
`

const AddLicenceLabel = styled.div`
  margin-left: 0.75rem;

  font-weight: 500;
`
