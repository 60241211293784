import { getMembers, getCompanies, getWork } from 'utils/api'
import WorkMemberInfo from 'models/WorkMemberInfo'

export const Actions = {
  RESET: 'RESET',
  SET_MEMBERS: 'SET_MEMBERS',
  SET_WORK: 'SET_WORK',
  SET_REPORT: 'SET_REPORT',
  DELETE_REPORT: 'DELETE_REPORT',
  ADD_MEMBER: 'ADD_MEMBER',
  SET_PERIOD: 'SET_PERIOD',
  SET_HOVERED: 'SET_HOVERED',
}

/**
 * ストアを初期ステートにリセット
 */
export function reset() {
  return {
    type: Actions.RESET,
  }
}

/**
 * Memberリストを更新
 * @param {Array.<Member>} members Memberインスタンスの配列
 */
export function setMembers(members) {
  return {
    type: Actions.SET_MEMBERS,
    members,
  }
}

/**
 * Memberリストをサーバーから取得
 */
export function fetchMembers() {
  return async dispatch => {
    // 全グループ企業オブジェクト
    const companies = await getCompanies()
    // 全従業員オブジェクト
    const members = await getMembers()
    // 全従業員オブジェクトを日報専用従業員配列に変換
    const infoArr = await Promise.all(
      Object.values(members).map(member =>
        WorkMemberInfo.fromMember(member, companies)
      )
    )
    const infoObj = {}

    // 配列をオブジェクトに変換
    infoArr.forEach(info => {
      infoObj[info.id] = info
    })

    dispatch(setMembers(infoObj))
  }
}

/**
 * 日報データを更新
 * @param {Work} work Work
 */
export function setWork(work) {
  return {
    type: Actions.SET_WORK,
    work,
  }
}

/**
 * 指定された現場のデータをサーバーから取得
 * @param {String} siteId
 * @param {moment} to この日から二週間
 */
export function fetchWork(siteId, to) {
  return dispatch => {
    return getWork(siteId, to).then(work => {
      dispatch(setWork(work))
    })
  }
}

/**
 * 特定の日報を更新
 * @param {String} memberId
 * @param {String} date
 * @param {Report} report
 */
export function setReport(memberId, date, report) {
  return {
    type: Actions.SET_REPORT,
    memberId,
    date,
    report,
  }
}

/**
 * 特定の日報を消去
 * @param {String} memberId
 * @param {String} date
 * @param {Number} schedule
 */
export function deleteReport(memberId, date, schedule) {
  return {
    type: Actions.DELETE_REPORT,
    memberId,
    date,
    schedule,
  }
}

/**
 * 表示する従業員を追加
 * @param {String} memberId
 */
export function addMember(memberId) {
  return {
    type: Actions.ADD_MEMBER,
    memberId,
  }
}

/**
 * 表示する期間の日数を変更
 * @param {Number} days 期間日数
 */
export function setPeriod(days) {
  return {
    type: Actions.SET_PERIOD,
    days,
  }
}

/**
 * ホバーされているセルの行、列のインデックスを更新
 * @param {Number} col
 * @param {Number} row
 */
export function setHovered(col, row) {
  return {
    type: Actions.SET_HOVERED,
    col,
    row,
  }
}
