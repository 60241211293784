import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { storage } from 'plugins/firebase'

import useAsync from 'hooks/useAsync'
import Member from 'models/Member'
import Company from 'models/Company'
import { colors } from 'consts/theme'
import {
  bloodTypes,
  healthInsuranceTypes,
  healthInsuranceNames,
  annuityInsuranceTypes,
  annuityInsuranceNames,
} from 'consts/member'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MemberResume = React.forwardRef(({ member, companies }, ref) => {
  // 顔写真URL
  const fetchFaceImgUrl = useCallback(async () => {
    if (!member.faceImg) {
      return ''
    }

    const ref = storage.ref(member.faceImg)
    return await ref.getDownloadURL()
  }, [member.faceImg])
  const { value: faceImgUrl } = useAsync(fetchFaceImgUrl)

  // 出力日
  const today = moment()
  const exportedOn = today.format('YYYY-MM-DD')

  // 所属名
  const companyName = companies[member.company]?.name

  // 誕生日
  const birth = member.birthday
  const birthdayText = birth
    ? `${birth.year()}年 ${
        birth.month() + 1
      }月 ${birth.date()}日 （${birth.diff(today, 'years')}歳）`
    : ''

  return (
    <HideWrapper>
      <Container ref={ref}>
        <Header>
          <Title>従業員情報</Title>
          <ExportedBy>
            exported on {exportedOn} with
            <ExportedByImage src="/logo.svg" />
          </ExportedBy>
        </Header>
        <Profile>
          <ProfileImage src={faceImgUrl} />
          <ProfileTextWrapper>
            <ProfileName>{member.name}</ProfileName>
            <ProfileRuby>{member.ruby}</ProfileRuby>
            {birthdayText !== '' && (
              <ProfileBirthday>
                <FontAwesomeIcon icon={['far', 'birthday-cake']} />
                <ProfileBirthdayText>{birthdayText}</ProfileBirthdayText>
              </ProfileBirthday>
            )}
          </ProfileTextWrapper>
        </Profile>

        <Col2>
          <Card>
            <CardHeader>業務情報</CardHeader>
            <CardTable>
              <tbody>
                <tr>
                  <td>所属</td>
                  <td>{companyName}</td>
                </tr>
                <tr>
                  <td>職種</td>
                  <td>{member.role.name}</td>
                </tr>
                <tr>
                  <td>雇入年月日</td>
                  <td>{member.employmentDate?.format('YYYY年M月D日')}</td>
                </tr>
                <tr>
                  <td>入場年月日</td>
                  <td>{member.entryDate?.format('YYYY年M月D日')}</td>
                </tr>
                <tr>
                  <td>経験年数</td>
                  <td>{member.experience}年</td>
                </tr>
              </tbody>
            </CardTable>
          </Card>
          <Card>
            <CardHeader>バイタリティ</CardHeader>
            <CardTable>
              <tbody>
                <tr>
                  <td>受診日時</td>
                  <td>{member.medical.date?.format('YYYY年M月D日')}</td>
                </tr>
                <tr>
                  <td>血圧</td>
                  <td>
                    上 {member.medical.pressure.high} / 下{' '}
                    {member.medical.pressure.low}
                  </td>
                </tr>
                <tr>
                  <td>血液型</td>
                  <td>{bloodTypes[member.bloodType]}</td>
                </tr>
                <tr>
                  <td>[特殊健康診断] 日時</td>
                  <td>{member.specialMedical.date?.format('YYYY年M月D日')}</td>
                </tr>
                <tr>
                  <td>[特殊健康診断] 種類</td>
                  <td>{member.specialMedical.type}</td>
                </tr>
              </tbody>
            </CardTable>
          </Card>
        </Col2>

        <Card>
          <CardHeader>住所と連絡先</CardHeader>
          <CardTable>
            <tbody>
              <tr>
                <td>住所</td>
                <td>{member.contact.address}</td>
              </tr>
              <tr>
                <td>電話番号</td>
                <td>{member.contact.tel}</td>
              </tr>
              <tr>
                <td>家族住所</td>
                <td>{member.familyContact.address}</td>
              </tr>
              <tr>
                <td>家族電話番号</td>
                <td>{member.familyContact.tel}</td>
              </tr>
            </tbody>
          </CardTable>
        </Card>

        <Card>
          <CardHeader>保険</CardHeader>
          <CardTable>
            <tbody>
              <tr>
                <td>[健康保険] 種別/番号</td>
                <td>
                  {member.insurance.health.type !== healthInsuranceTypes.none &&
                    `${healthInsuranceNames[member.insurance.health.type]}/${
                      member.insurance.health.number
                    }`}
                </td>
              </tr>
              <tr>
                <td>[年金保険] 種別/番号</td>
                <td>
                  {member.insurance.annuity.type !==
                    annuityInsuranceTypes.none &&
                    `${annuityInsuranceNames[member.insurance.annuity.type]}/${
                      member.insurance.annuity.number
                    }`}
                </td>
              </tr>
              <tr>
                <td>[雇用保険] 種別/番号</td>
                <td>
                  {member.insurance.employment.type &&
                    `${member.insurance.employment.type}/${member.insurance.employment.number}`}
                </td>
              </tr>
            </tbody>
          </CardTable>
        </Card>

        <Card>
          <CardHeader>教育・資格・免許等</CardHeader>
          <CardTable>
            <tbody>
              <tr>
                <td>受入教育実施年月日</td>
                <td>{member.educateDate?.format('YYYY年M月D日')}</td>
              </tr>
              <tr>
                <td>雇入・職長 特別教育</td>
                <td>{member.skills.leader}</td>
              </tr>
              <tr>
                <td>技能講習</td>
                <td>{member.skills.technic}</td>
              </tr>
              <tr>
                <td>在留カード番号</td>
                <td>{member.residenceNumber}</td>
              </tr>
              <tr>
                <td>[免許] 種別/番号</td>
                <td>
                  <LicenceGrid>
                    {member.licenses.map((licence, ind) => (
                      <div key={ind}>
                        {licence.type}/{licence.number}
                      </div>
                    ))}
                  </LicenceGrid>
                </td>
              </tr>
            </tbody>
          </CardTable>
        </Card>
      </Container>
    </HideWrapper>
  )
})

MemberResume.displayName = 'MemberResume'

MemberResume.propTypes = {
  member: PropTypes.instanceOf(Member).isRequired,
  companies: PropTypes.objectOf(PropTypes.instanceOf(Company)).isRequired,
}

export default MemberResume

const HideWrapper = styled.div`
  display: none;
`

const Container = styled.div`
  padding: 1.5rem;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.h1`
  color: ${colors.text.dark};
  font-size: 1rem;
  font-weight: bold;
`

const ExportedBy = styled.div`
  display: flex;
  align-items: center;

  width: 176px;

  color: ${colors.text.light};
  font-size: 0.625rem;
`

const ExportedByImage = styled.img`
  height: 0.75rem;
  margin-left: 0.25rem;
`

const Profile = styled.div`
  display: flex;
  align-items: center;

  margin-top: 1rem;
`

const ProfileImage = styled.div`
  flex: 0 0 auto;

  width: 6rem;
  height: 6rem;

  border-radius: 0.5rem;
  background-color: ${p => (p.src ? '#fff' : colors.gray[200])};
  background-image: url(${p => p.src});
  background-size: cover;
  background-position: center;
`

const ProfileTextWrapper = styled.div`
  flex: 1 1 0;

  margin-left: 1rem;
`

const ProfileName = styled.h2`
  color: ${colors.text.dark};
  font-size: 1rem;
  font-weight: bold;
`

const ProfileRuby = styled.div`
  color: ${colors.text.base};
  font-size: 0.75rem;
`

const ProfileBirthday = styled.div`
  display: flex;
  align-items: center;

  margin-top: 0.25rem;

  color: ${colors.text.dark};
  font-size: 0.75rem;
`

const ProfileBirthdayText = styled.div`
  font-size: 0.625rem;
`

const Col2 = styled.div`
  display: flex;
  align-items: flex-start;

  width: 100%;

  > *:last-child {
    margin-left: 1rem;
  }
`

const Card = styled.div`
  width: 100%;
  margin-top: 1rem;
  border: 1px solid ${colors.gray[400]};
  border-radius: 0.5rem;
`

const CardHeader = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
  background: ${colors.gray[200]};

  color: ${colors.text.dark};
  font-size: 0.75rem;
  font-weight: bold;
`

const CardTable = styled.table.attrs({
  cellpadding: 1,
  cellspacing: 2,
})`
  width: 100%;
  margin: 0;
  padding: 0.5rem 1rem;

  color: ${colors.text.base};
  font-size: 0.75rem;

  td {
    vertical-align: baseline;
  }
`

const LicenceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
`
