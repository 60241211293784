import React, { useState } from 'react'
import styled from 'styled-components'

import useAsync from '../hooks/useAsync'
import { colors } from '../consts/theme'

import { getSites } from 'utils/api'

import AppLayout from '../layouts/AppLayout'
import SiteList from 'components/site-select/atoms/SiteList'
import SearchBox from 'components/common/atoms/SearchBox'
import LoadingIndicator from 'components/common/molecules/LoadingIndicator'

const WorkSiteSelect = () => {
  const [input, setInput] = useState('')
  const { pending, value: sites } = useAsync(getSites, true)

  const onInputChange = e => {
    setInput(e.target.value)
  }

  const filteredSites = sites?.filter(
    ({ name, clientName }) => name.includes(input) || clientName.includes(input)
  )

  return (
    <AppLayout title="現場選択">
      <Container>
        <SearchBoxWrapper>
          <SearchBox
            value={input}
            onChange={onInputChange}
            placeholder="現場名もしくは企業名で絞り込む"
          />
        </SearchBoxWrapper>

        <ListWrapper>
          {pending ? (
            <LoadingIndicator size="2.5rem" />
          ) : (
            <SiteList sites={filteredSites} basePath="/work/" />
          )}
        </ListWrapper>
      </Container>
    </AppLayout>
  )
}

export default WorkSiteSelect

const Container = styled.section`
  flex: 1;

  width: 100%;
  padding: 2rem;

  border-top-left-radius: 1rem;
  background: ${colors.gray[100]};
`

const SearchBoxWrapper = styled.div`
  width: 24rem;
`

const ListWrapper = styled.div`
  margin-top: 2rem;
`
