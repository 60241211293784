import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import useNotification, { notificationTypes } from 'hooks/useNotification'
import { colors } from 'consts/theme'
import { exportMembers } from 'utils/api'

import RaisedButton from 'components/common/atoms/RaisedButton'
import ValidatableForm from 'components/common/atoms/ValidatableForm'
import TextField from 'components/common/atoms/TextField'

const MONTH_RULES = [
  v => {
    if (/^\d{4}-\d{2}$/.test(v)) {
      return true
    }
    return 'YYYY-MM の形式で入力してください'
  },
]

const ExportMembers = () => {
  const [monthInput, setMonthInput] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const showNotification = useNotification()

  const onChange = useCallback(e => {
    setMonthInput(e.target.value)
  }, [])

  const onSubmit = useCallback(
    async e => {
      e.preventDefault()

      if (!moment(monthInput).isValid()) {
        showNotification(
          notificationTypes.ERROR,
          '不正な出力月',
          '正しい形式で出力月を入力してください。'
        )
        return
      }

      setIsLoading(true)

      try {
        const res = await exportMembers(monthInput)
        const { base64, filename } = res.data
        const binaryStr = atob(base64)
        const u8 = Uint8Array.from(binaryStr.split(''), e => e.charCodeAt(0))
        const blob = new Blob([u8], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        if (window.navigator.msSaveOrOpenBlob) {
          // IE, Edge向け
          window.navigator.msSaveOrOpenBlob(blob, filename)
        } else {
          const url = URL.createObjectURL(blob)
          const el = document.createElement('a')

          // ダウンロードを実行
          el.href = url
          el.setAttribute('download', filename)
          document.body.appendChild(el)
          el.click()

          // インスタンスとDOMエレメントを開放
          URL.revokeObjectURL(url)
          el.parentNode.removeChild(el)
        }
      } catch (error) {
        showNotification(
          notificationTypes.ERROR,
          'エラーが発生しました',
          `出面表の出力中にエラーが発生しました。時間をおいて、再度お試しください。${error.message}`
        )
      } finally {
        setIsLoading(false)
      }
    },
    [monthInput, showNotification]
  )

  return (
    <>
      <Description>
        すべての従業員をまとめた出面表を Excel 形式で出力します。
      </Description>

      <ValidatableForm
        onSubmit={onSubmit}
        onValidated={isValid => setIsValid(isValid)}
      >
        <StyledTextField
          value={monthInput}
          onChange={onChange}
          label="出力月"
          rules={MONTH_RULES}
        />

        <RaisedButton
          type="submit"
          loading={isLoading}
          disabled={!isValid}
          long
        >
          出力
        </RaisedButton>
      </ValidatableForm>
    </>
  )
}

export default ExportMembers

const Description = styled.p`
  margin-bottom: 1.5rem;

  color: ${colors.text.base};
`

const StyledTextField = styled(TextField)`
  width: 20rem;
  margin-bottom: 1.5rem;
`
