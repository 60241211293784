import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactToPrint from 'react-to-print'

import useNotification, { notificationTypes } from 'hooks/useNotification'
import Member from 'models/Member'
import Company from 'models/Company'
import { colors } from 'consts/theme'

import MemberResume from './MemberResume'
import MemberImages from './MemberImages'
import Radio from 'components/common/atoms/Radio'
import RaisedButton from 'components/common/atoms/RaisedButton'
import FlatButton from 'components/common/atoms/FlatButton'

const ResumeModal = ({ member, companies, onClose }) => {
  const [exportTargetRadio, setExportTargetRadio] = useState({
    resume: true,
    images: false,
  })
  const [isExporting, setIsExporting] = useState(false)
  const resumeRef = useRef()
  const imagesRef = useRef()
  const showNotification = useNotification()

  const onRadioChanged = useCallback((name, isChecked) => {
    setExportTargetRadio(v => ({
      resume: false,
      images: false,
      [name]: isChecked,
    }))
  }, [])

  const onBeforeGetContent = useCallback(() => {
    setIsExporting(true)
  }, [])

  const onAfterPrint = useCallback(() => {
    setIsExporting(false)
  }, [])

  const onPrintError = useCallback(
    (errLocation, err) => {
      showNotification(
        notificationTypes.ERROR,
        '出力に失敗しました',
        '何らかの理由で出力が中断されました。再度お試しください。'
      )

      console.error(err, errLocation)

      setIsExporting(false)
    },
    [showNotification]
  )

  return (
    <Container>
      <Title>従業員の情報を出力</Title>
      <Description>
        <Bold>{member.name}</Bold> の情報をまとめた PDF を出力できます。
        <br />
        また、画像が必要な場合は画像をまとめた PDF を出力できます。
      </Description>

      <TargetSelection>
        <RadioWrapper>
          <Radio
            value={exportTargetRadio.resume}
            onChange={e => onRadioChanged('resume', e.target.checked)}
          />
          <RadioLabel>従業員情報のみを出力</RadioLabel>
        </RadioWrapper>
        <RadioWrapper>
          <Radio
            value={exportTargetRadio.images}
            onChange={e => onRadioChanged('images', e.target.checked)}
          />
          <RadioLabel>画像のみを出力</RadioLabel>
        </RadioWrapper>
      </TargetSelection>

      <Trailing>
        <FlatButton onClick={onClose}>キャンセル</FlatButton>
        <ReactToPrint
          trigger={() => (
            <RaisedButton loading={isExporting} long>
              出力
            </RaisedButton>
          )}
          content={() =>
            exportTargetRadio.resume ? resumeRef.current : imagesRef.current
          }
          onBeforeGetContent={onBeforeGetContent}
          onAfterPrint={onAfterPrint}
          onPrintError={onPrintError}
        />
        <MemberResume member={member} companies={companies} ref={resumeRef} />
        <MemberImages member={member} ref={imagesRef} />
      </Trailing>
    </Container>
  )
}

ResumeModal.propTypes = {
  member: PropTypes.instanceOf(Member).isRequired,
  companies: PropTypes.objectOf(PropTypes.instanceOf(Company)).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ResumeModal

const Container = styled.div`
  max-width: 40rem;
`

const Title = styled.h1`
  color: ${colors.text.dark};
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: bold;
`

const Description = styled.p`
  margin-top: 1rem;
  color: ${colors.text.base};
`

const Bold = styled.span`
  font-weight: bold;
`

const TargetSelection = styled.div`
  margin-top: 2rem;
`

const RadioWrapper = styled.label`
  display: flex;
  align-items: center;

  cursor: pointer;

  margin-top: 1rem;
`

const RadioLabel = styled.div`
  margin-left: 0.75rem;

  color: ${colors.text.dark};
  font-size: 1rem;
  font-weight: 500;
`

const Trailing = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 2rem;

  > * {
    margin-left: 1rem;
  }
`
