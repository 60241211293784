import produce from 'immer'

import { Actions } from '../actions/work-actions'
import { putReport, deleteReport } from 'utils/api'

import Report from 'models/Report'
import WorkMember from 'models/WorkMember'

const initialState = {
  members: {},
  work: null,
  period: 14,
  hoveredCol: null,
  hoveredRow: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.RESET: {
      return initialState
    }
    case Actions.SET_MEMBERS: {
      return {
        ...state,
        members: action.members,
      }
    }
    case Actions.SET_WORK: {
      return produce(state, draft => {
        if (draft.work == null) {
          // まだworkが未設定の場合、そのまま追加
          draft.work = action.work
        } else {
          // 既にworkがセットされている場合は合成する
          Object.entries(action.work.members).forEach(([memberId, member]) => {
            // 新しい作業員の場合はインスタンスを生成
            if (draft.work.members[memberId] == null) {
              draft.work.members[memberId] = member
            } else {
              // 全ての日付を合成
              Object.entries(member.reports).forEach(([date, report]) => {
                draft.work.members[memberId].reports[date] = report
              })
            }
          })
        }
      })
    }
    case Actions.SET_REPORT: {
      return produce(state, draft => {
        draft.work.members[action.memberId].reports[action.date] = action.report

        putReport(
          draft.work.siteId,
          action.memberId,
          action.date,
          action.report
        )
      })
    }
    case Actions.DELETE_REPORT: {
      return produce(state, draft => {
        if (action.schedule > 0) {
          draft.work.members[action.memberId].reports[action.date] = new Report(
            {
              date: action.date,
              schedule: action.schedule,
            }
          )
        } else {
          draft.work.members[action.memberId].reports[action.date] = undefined
        }

        deleteReport(draft.work.siteId, action.memberId, action.date)
      })
    }
    case Actions.ADD_MEMBER: {
      return produce(state, draft => {
        draft.work.members[action.memberId] = new WorkMember({
          id: action.memberId,
          reports: {},
        })
      })
    }
    case Actions.SET_PERIOD: {
      return {
        ...state,
        period: action.days,
      }
    }
    case Actions.SET_HOVERED: {
      return {
        ...state,
        hoveredCol: action.col,
        hoveredRow: action.row,
      }
    }
    default:
      return state
  }
}

export default reducer
