import authAxios from './authAxios'
import { auth } from 'plugins/firebase'

import Member from 'models/Member'
import Schedule from 'models/Schedule'
import Site from 'models/Site'
import Work from 'models/Work'
import Client from 'models/Client'
import Company from 'models/Company'
import User from 'models/User'

/*******************************
 * common
 *******************************/

export async function getCurrentUser() {
  if (!auth.currentUser) {
    return null
  }

  const res = await authAxios.get('/currentUser')

  return {
    id: auth.currentUser.uid,
    ...res.data,
  }
}

/*******************************
 * schedule
 *******************************/

/**
 * スケジュールを取得
 * @param {Number} year
 * @param {Number} month
 * @returns {Object} 取得したScheduleインスタンスを日付でインデックスしたObjectに変換したもの
 */
export function getSchedules(year, month) {
  return authAxios
    .get('/schedules', {
      params: {
        date: `${year}-${('00' + month).slice(-2)}`,
      },
    })
    .then(res => {
      const schedules = {}

      // 取得できた全ての日付に対して、それぞれScheduleインスタンスを生成
      Object.entries(res.data).forEach(([date, schedule]) => {
        schedules[date] = Schedule.fromJSON(schedule, date)
      })

      return schedules
    })
}

/**
 * スケジュールを更新
 * @param {moment} date
 * @param {ScheduleSite} site
 */
export function updateSchedule(date, site) {
  const req = {}

  req.id = site.id
  req.siteId = site.siteId
  req.date = date.format('YYYY-MM-DD')
  req.note = site.note
  req.members = site.members.map(m => m.toJSON())

  return authAxios.put('/schedules', req)
}

/*******************************
 * work
 *******************************/

/**
 * 日報入力状況を取得
 * @param {string} siteId 取得したい現場
 * @param {moment} to 取得したい二週間の最後の日
 * @returns {Work} 取得結果
 */
export function getWork(siteId, to) {
  const from = to.clone().subtract(14, 'days')

  return authAxios
    .get('/works', {
      params: {
        siteId,
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD'),
      },
    })
    .then(res => {
      return Work.fromJSON({ siteId, ...res.data })
    })
}

/**
 * 日報を登録、更新
 * @param {String} siteId
 * @param {String} memberId
 * @param {String} date
 * @param {Report} report
 */
export function putReport(siteId, memberId, date, report) {
  return authAxios.put('/works', {
    siteId,
    memberId,
    date,
    report,
  })
}

export function deleteReport(siteId, memberId, date) {
  return authAxios.delete('/works', {
    data: {
      siteId,
      memberId,
      date,
    },
  })
}

/*******************************
 * export
 *******************************/

export function exportMembers(month) {
  return authAxios.get('/export', {
    params: {
      type: 'MEMBER',
      month,
    },
  })
}

export function exportCompany(month, companyId) {
  return authAxios.get('/export', {
    params: {
      type: 'COMPANY',
      month,
      companyId,
    },
  })
}

export function exportSite(month, siteId) {
  return authAxios.get('/export', {
    params: {
      type: 'SITE',
      month,
      siteId,
    },
  })
}

/*******************************
 * sites
 *******************************/

/**
 * 全ての現場を取得
 * @returns {Array.<Site>} 取得したSiteインスタンスの配列
 */
export function getSites() {
  return authAxios.get('/sites').then(res => {
    const sites = []

    Object.keys(res.data).forEach(id => {
      sites.push(Site.fromJSON({ id, ...res.data[id] }))
    })

    return sites
  })
}

/**
 * 現場を追加
 * @param {Site} site
 */
export function addSite(site) {
  const payload = JSON.parse(JSON.stringify(site))

  // siteIdを送らないPUTは追加になる（詳しくはドキュメント参照）
  payload.siteId = undefined

  return authAxios.put('/sites', payload)
}

/**
 * 現場を編集
 * @param {string} siteId
 * @param {Site} site
 */
export function editSite(siteId, site) {
  const payload = JSON.parse(JSON.stringify(site))

  payload.siteId = siteId

  return authAxios.put('/sites', payload)
}

/**
 * 現場を削除
 * @param {string} siteId
 */
export function deleteSite(siteId) {
  return authAxios.delete('/sites', {
    data: {
      siteId,
    },
  })
}

/*******************************
 * members
 *******************************/

/**
 * 全ての作業員を取得
 * @returns {Array.<Member>} 取得したMemberインスタンスの配列
 */
export function getMembers() {
  return authAxios.get('/members').then(res => {
    const members = {}

    Object.entries(res.data).forEach(([id, member]) => {
      members[id] = Member.fromJSON(id, member)
    })

    return members
  })
}

/**
 * 作業員を追加
 * @param {Member} site
 */
export function addMember(member) {
  const payload = JSON.parse(JSON.stringify(member))

  // memberIdを送らないPUTは追加になる（詳しくはドキュメント参照）
  payload.memberId = undefined

  return authAxios.put('/members', payload)
}

/**
 * 作業員を編集
 * @param {string} memberId
 * @param {Member} member
 */
export function editMember(memberId, member) {
  const payload = JSON.parse(JSON.stringify(member))

  payload.memberId = memberId

  return authAxios.put('/members', payload)
}

/**
 * 作業員を削除
 * @param {string} memberId
 */
export function deleteMember(memberId) {
  return authAxios.delete('/members', {
    data: {
      memberId,
    },
  })
}

/*******************************
 * clients
 *******************************/

/**
 * 全ての作業員を取得
 * @returns {Object<id, Client>} 取得したClientインスタンスのオブジェクト（IDがkey）
 */
export function getClients() {
  return authAxios.get('/clients').then(res => {
    const clients = {}

    Object.entries(res.data).forEach(([id, client]) => {
      clients[id] = Client.fromJSON({ id, ...client })
    })

    return clients
  })
}

/**
 * クライアント企業を追加
 * @param {Client} client
 */
export function addClient(client) {
  const payload = JSON.parse(JSON.stringify(client))

  // clientIdを送らないPUTは追加になる（詳しくはドキュメント参照）
  payload.clientId = undefined

  return authAxios.put('/clients', payload)
}

/**
 * クライアント企業を編集
 * @param {string} clientId
 * @param {Client} client
 */
export function editClient(clientId, client) {
  const payload = JSON.parse(JSON.stringify(client))

  payload.clientId = clientId

  return authAxios.put('/clients', payload)
}

/**
 * クライアント企業を削除
 * @param {string} clientId
 */
export function deleteClient(clientId) {
  return authAxios.delete('/clients', {
    data: {
      clientId,
    },
  })
}

/*******************************
 * companies
 *******************************/

/**
 * 全てのグループ企業を取得
 * @returns {Object<id, Company>} 取得したCompanyインスタンスのオブジェクト（IDがkey）
 */
export function getCompanies() {
  return authAxios.get('/companies').then(res => {
    const companies = {}

    Object.entries(res.data).forEach(([id, company]) => {
      companies[id] = Company.fromJSON({ id, ...company })
    })

    return companies
  })
}

/**
 * グループ企業を追加
 * @param {Company} site
 */
export function addCompany(company) {
  const payload = JSON.parse(JSON.stringify(company))

  // companyIdを送らないPUTは追加になる（詳しくはドキュメント参照）
  payload.companyId = undefined

  return authAxios.put('/companies', payload)
}

/**
 * グループ企業を編集
 * @param {string} companyId
 * @param {Company} company
 */
export function editCompany(companyId, company) {
  const payload = JSON.parse(JSON.stringify(company))

  payload.companyId = companyId

  return authAxios.put('/companies', payload)
}

/**
 * グループ企業を削除
 * @param {string} companyId
 */
export function deleteCompany(companyId) {
  return authAxios.delete('/companies', {
    data: {
      companyId,
    },
  })
}

/*******************************
 * users
 *******************************/

/**
 * 全てのユーザーを取得
 * @returns {Object<id, User>} 取得したUserインスタンスのオブジェクト（IDがkey）
 */
export function getUsers() {
  return authAxios.get('/users').then(res => {
    const users = {}

    Object.entries(res.data).forEach(([id, user]) => {
      users[id] = User.fromJSON({ id, ...user })
    })

    return users
  })
}

/**
 * ユーザーを追加
 * @param {User} user
 */
export function addUser(user) {
  return authAxios.post('/users', {
    ...user,
  })
}

/**
 * ユーザーを編集
 * @param {string} userId
 * @param {User} user
 */
export function editUser(userId, user) {
  return authAxios.put('/users', { ...user, userId })
}

/**
 * ユーザーを削除
 * @param {string} userId
 */
export function deleteUser(userId) {
  return authAxios.delete('/users', {
    data: {
      userId,
    },
  })
}

/**
 * ユーザーのパスワードをリセット
 * @param {string} userId
 */
export function resetPassword(userId) {
  return authAxios.patch('/users', {
    userId,
    password: true,
  })
}
