import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { storage } from 'plugins/firebase'

import useNotification, { notificationTypes } from 'hooks/useNotification'
import useAsync from 'hooks/useAsync'
import Member from 'models/Member'
import { colors } from 'consts/theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MemberImages = React.forwardRef(({ member }, ref) => {
  const showNotification = useNotification()

  // 全ての画像のURLを取得
  const handleImageError = useCallback(
    name => {
      showNotification(
        notificationTypes.ERROR,
        '画像の読み込みに失敗',
        `この従業員に登録された${name}の読み込みに失敗しました。`
      )
    },
    [showNotification]
  )
  const fetchImgUrls = useCallback(async () => {
    const promises = []

    if (member.residenceImg != null) {
      try {
        const ref = storage.ref(member.residenceImg)
        promises.push(
          ref
            .getDownloadURL()
            .then(url => ({ url, name: '在留カード写真' }))
            .catch(() => handleImageError('在留カード写真'))
        )
      } catch (e) {
        handleImageError('在留カード写真')
      }
    }

    if (member.licenseImg != null) {
      member.licenseImg.forEach(path => {
        try {
          const ref = storage.ref(path)
          promises.push(
            ref
              .getDownloadURL()
              .then(url => ({ url, name: path.split('/')[2] }))
              .catch(() => handleImageError(path.split('/')[2]))
          )
        } catch (e) {
          handleImageError(path.split('/')[2])
        }
      })
    }

    const urls = await Promise.all(promises)
    return urls.filter(url => !!url)
  }, [handleImageError, member.licenseImg, member.residenceImg])
  const { value: imgUrls } = useAsync(fetchImgUrls)

  // 顔写真URL
  const fetchFaceImgUrl = useCallback(async () => {
    if (!member.faceImg) {
      return ''
    }

    const ref = storage.ref(member.faceImg)
    return await ref.getDownloadURL()
  }, [member.faceImg])
  const { value: faceImgUrl } = useAsync(fetchFaceImgUrl)

  // 出力日
  const today = moment()
  const exportedOn = today.format('YYYY-MM-DD')

  // 誕生日
  const birth = member.birthday
  const birthdayText = birth
    ? `${birth.year()}年 ${
        birth.month() + 1
      }月 ${birth.date()}日 （${birth.diff(today, 'years')}歳）`
    : ''

  return (
    <HideWrapper>
      <Container ref={ref}>
        <Header>
          <Title>従業員に紐づく画像</Title>
          <ExportedBy>
            exported on {exportedOn} with
            <ExportedByImage src="/logo.svg" />
          </ExportedBy>
        </Header>
        <Profile>
          <ProfileImage src={faceImgUrl} />
          <ProfileTextWrapper>
            <ProfileName>{member.name}</ProfileName>
            <ProfileRuby>{member.ruby}</ProfileRuby>
            <ProfileBirthday>
              <FontAwesomeIcon icon={['far', 'birthday-cake']} />
              <ProfileBirthdayText>{birthdayText}</ProfileBirthdayText>
            </ProfileBirthday>
          </ProfileTextWrapper>
        </Profile>

        {imgUrls?.map(({ url, name }) => (
          <ImgWrapper key={url}>
            <StyledImg src={url} />
            <ImgCaption>{name}</ImgCaption>
          </ImgWrapper>
        ))}
      </Container>
    </HideWrapper>
  )
})

MemberImages.displayName = 'MemberImages'

MemberImages.propTypes = {
  member: PropTypes.instanceOf(Member).isRequired,
}

export default MemberImages

const HideWrapper = styled.div`
  display: none;
`

const Container = styled.div`
  padding: 1.5rem;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.h1`
  color: ${colors.text.dark};
  font-size: 1rem;
  font-weight: bold;
`

const ExportedBy = styled.div`
  display: flex;
  align-items: center;

  width: 176px;

  color: ${colors.text.light};
  font-size: 0.625rem;
`

const ExportedByImage = styled.img`
  height: 0.75rem;
  margin-left: 0.25rem;
`

const Profile = styled.div`
  display: flex;
  align-items: center;

  margin-top: 1rem;
`

const ProfileImage = styled.div`
  flex: 0 0 auto;

  width: 6rem;
  height: 6rem;

  border-radius: 0.5rem;
  background-color: ${p => (p.src ? '#fff' : colors.gray[200])};
  background-image: url(${p => p.src});
  background-size: cover;
  background-position: center;
`

const ProfileTextWrapper = styled.div`
  flex: 1 1 0;

  margin-left: 1rem;
`

const ProfileName = styled.h2`
  color: ${colors.text.dark};
  font-size: 1rem;
  font-weight: bold;
`

const ProfileRuby = styled.div`
  color: ${colors.text.base};
  font-size: 0.75rem;
`

const ProfileBirthday = styled.div`
  display: flex;
  align-items: center;

  margin-top: 0.25rem;

  color: ${colors.text.dark};
  font-size: 0.75rem;
`

const ProfileBirthdayText = styled.div`
  font-size: 0.625rem;
`

const ImgWrapper = styled.div`
  text-align: center;
`

const StyledImg = styled.img`
  max-width: 100%;
  margin-top: 2rem;
`

const ImgCaption = styled.p`
  margin-top: 0.5rem;

  color: ${colors.text.base};
  font-size: 0.75rem;
  font-weight: 500;
`
