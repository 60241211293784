import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { userRoles } from 'consts/user'

import ProtectedRoute from './ProtectedRoute'

import LoginPage from 'pages/Login'
import LogoutPage from 'pages/Logout'
import NotFoundPage from 'pages/NotFound'
import SchedulePage from 'pages/Schedule'
import NotAuthorizedPage from 'pages/NotAuthorized'
import SettingsPage from 'pages/Settings'
import WorkSiteSelectPage from 'pages/WorkSiteSelect'
import WorkPage from 'pages/Work'
import SitesPage from 'pages/Sites'
import MembersPage from 'pages/Members'
import ClientsPage from 'pages/Clients'
import CompaniesPage from 'pages/Companies'
import ExportPage from 'pages/Export'
import UsersPage from 'pages/Users'
import LogsPage from 'pages/Logs'

const Router = () => {
  return (
    <Switch>
      <Redirect exact path="/" to="/login" />

      <Route exact path="/login">
        <LoginPage />
      </Route>
      <Route exact path="/logout">
        <LogoutPage />
      </Route>
      <Route exact path="/not-authorized">
        <NotAuthorizedPage />
      </Route>

      <ProtectedRoute exact path="/settings" roles={[]}>
        <SettingsPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/schedule" roles={[userRoles.SCHEDULER]}>
        <SchedulePage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/work" roles={[userRoles.ACCOUNTANT]}>
        <WorkSiteSelectPage />
      </ProtectedRoute>
      <ProtectedRoute path="/work/:siteId" roles={[userRoles.ACCOUNTANT]}>
        <WorkPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/export" roles={[userRoles.ACCOUNTANT]}>
        <ExportPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/sites" roles={[userRoles.ADMIN]}>
        <SitesPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/members" roles={[userRoles.ADMIN]}>
        <MembersPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/clients" roles={[userRoles.ADMIN]}>
        <ClientsPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/companies" roles={[userRoles.ADMIN]}>
        <CompaniesPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/users" roles={[userRoles.ADMIN]}>
        <UsersPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/logs" roles={[userRoles.ADMIN]}>
        <LogsPage />
      </ProtectedRoute>

      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}

export default Router
