import React, { useState, useEffect, useCallback } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { Provider as ReduxProvider } from 'react-redux'
import reducer from 'redux/reducers/reducer'
import { uid } from 'react-uid'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import * as Sentry from '@sentry/browser'
import 'plugins/firebase'

import { auth } from 'plugins/firebase'
import User from 'models/User'
import Notification from 'models/Notification'
import UserContext from 'contexts/User'
import ModalContext from 'contexts/Modal'
import NotificationContext from 'contexts/Notification'
import { notificationTypes } from 'consts/notification'

import Router from './Router'
import NotificationContainer from 'components/common/molecules/NotificationContainer'
import ErrorBoundary from 'layouts/ErrorBoundary'

// 全てのFontAwesomeアイコンをライブラリに追加
library.add(fas, far, fal, fad)

// Senrtyを初期化
Sentry.init({
  dsn:
    'https://fb5a97ffdc97475a9762ef0c3ba5885f@o400318.ingest.sentry.io/5258636',
  environment: process.env.REACT_APP_SENTRY_ENV,
})

const store = createStore(
  reducer,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
)

const App = () => {
  const [userContextValue, setUserContextValue] = useState({
    user: null,
    isInitializing: true,
    /**
     * 現在ログインしているユーザーの情報を取得し、コンテキストに保存する
     */
    async login() {
      // コンテキストにユーザーデータを保存
      const user = await User.getCurrentUser()
      setUserContextValue(crt => ({ ...crt, user }))
    },
    /**
     * コンテキストからユーザー情報を削除
     */
    logout() {
      setUserContextValue(crt => ({
        ...crt,
        user: null,
      }))
    },
  })

  useEffect(() => {
    auth.onAuthStateChanged(async user => {
      try {
        // 初期状態でログインしていない場合やログアウトされた場合
        if (!user) {
          userContextValue.logout()
          return
        }

        // ログインしているのでコンテキストに登録
        await userContextValue.login()
      } catch (err) {
        // エラーが発生したら一度ログアウトさせる
        notificationContextValue.showNotification(
          new Notification({
            type: notificationTypes.ERROR,
            title: 'ログイン処理に失敗',
            description:
              'ログイン処理を行っている間にエラーが発生しました。しばらく時間を空けてから再度お試しください。',
          }),
          7000
        )
        userContextValue.logout()
      } finally {
        // 前回のログイン状態の確認を完了
        setUserContextValue(crt => ({
          ...crt,
          isInitializing: false,
        }))
      }
    })
    // 無限ループを防ぐためにESLintの自動依存変数代入機能を無効化
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * モーダル関連
   */
  const [modals, setModals] = useState([])
  const [modalContextValue] = useState({
    /**
     * モーダルコンポーネントを開く
     * @param {ModalComponent} modal 表示するモーダルコンポーネント
     * @returns {Number} 表示したコンポーネントのID（これをcloseModalに渡すことで、モーダルを消すことができる）
     */
    openModal(modal) {
      let newModals = []

      setModals(modals => {
        newModals = [...modals]

        // キーを付与したFragmentで囲んでモーダルを追加
        newModals.push(
          <React.Fragment key={newModals.length}>{modal}</React.Fragment>
        )

        return newModals
      })

      return newModals.length - 1
    },
    /**
     * モーダルコンポーネントを閉じる
     * @param {Number} id 閉じるコンポーネントのID
     */
    closeModal(id) {
      setModals(modals => {
        const newModals = [...modals]

        // 指定されたIDのインデックスのモーダルを削除
        newModals.splice(id, 1)

        return newModals
      })
    },
  })

  /**
   * 通知関連
   */
  const [notifications, setNotifications] = useState([])

  /**
   * 通知を消去する
   * @param {String} id
   */
  const removeNotification = useCallback(id => {
    setNotifications(notifications => {
      const newNotifs = [...notifications]
      const targetInd = newNotifs.findIndex(notif => notif.id === id)

      newNotifs.splice(targetInd, 1)

      return newNotifs
    })
  }, [])

  /**
   * 通知を閉じるアニメーションを開始する
   * @param {String} id
   */
  const hideNotification = useCallback(id => {
    // useStateに関数をわたすことで、現在の値を取得できる
    setNotifications(notifications => {
      const newNotifs = [...notifications]
      const targetInd = newNotifs.findIndex(notif => notif.id === id)

      if (targetInd === -1) {
        throw new Error(
          '指定されたIDの通知が見つからなかったため、閉じることができませんでした'
        )
      }

      newNotifs[targetInd].close()

      return newNotifs
    })
  }, [])

  const [notificationContextValue] = useState({
    /**
     * 通知を開く
     * @param {Notification} notification 表示する通知インスタンス
     * @param {Number} duration 表示する時間
     * @returns {Number} 表示した通知のID（これをhideNotificationに渡すことで、通知を消すことができる）
     */
    showNotification(notif, duration) {
      setNotifications(notifications => {
        const newNotifs = [...notifications]
        const id = uid(notif)

        // 通知を追加
        notif.setId(id)
        newNotifs.unshift(notif)

        setTimeout(() => {
          // 通知を消す
          hideNotification(id)
        }, duration)

        return setNotifications(newNotifs)
      })
    },
  })

  return (
    <ReduxProvider store={store}>
      <UserContext.Provider value={userContextValue}>
        <BrowserRouter>
          <ErrorBoundary>
            <ModalContext.Provider value={modalContextValue}>
              <NotificationContext.Provider value={notificationContextValue}>
                {modals}
                <NotificationContainer
                  notifications={notifications}
                  onRemove={removeNotification}
                />
                <Router />
              </NotificationContext.Provider>
            </ModalContext.Provider>
          </ErrorBoundary>
        </BrowserRouter>
      </UserContext.Provider>
    </ReduxProvider>
  )
}

export default App
