import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

import { auth } from 'plugins/firebase'
import { colors } from 'consts/theme'
import { isLengthGreaterThanOrEqual } from 'consts/rules'
import useModal from 'hooks/useModal'
import useNotification, { notificationTypes } from 'hooks/useNotification'
import useRouter from 'hooks/useRouter'

import AppLayout from 'layouts/AppLayout'
import ValidatableForm from 'components/common/atoms/ValidatableForm'
import TextField from 'components/common/atoms/TextField'
import RaisedButton from 'components/common/atoms/RaisedButton'
import RequireReLoginModal from 'components/settings/molecules/RequireReLoginModal'

const passwordRules = [isLengthGreaterThanOrEqual(6)]

const Settings = () => {
  const [isValid, setIsValid] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(null)
  const [isSending, setIsSending] = useState(false)
  const [openModal, closeModal] = useModal()
  const showNotification = useNotification()
  const router = useRouter()

  const onPasswordChange = useCallback(
    e => {
      if (passwordError) setPasswordError(null)

      setPassword(e.target.value)
    },
    [passwordError]
  )

  const onPasswordSubmit = useCallback(
    e => {
      e.preventDefault()

      if (!isValid) return

      const crtUser = auth.currentUser

      setIsSending(true)

      crtUser
        .updatePassword(password)
        .then(() => {
          showNotification(
            notificationTypes.INFO,
            'パスワードを変更しました',
            'パスワードの変更が完了しました。新しいパスワードを使ってログインし直してください。'
          )
          router.push('/logout')
        })
        .catch(err => {
          if (err.code === 'auth/weak-password') {
            // パスワードの強度が低いために発生するエラー

            setPasswordError('パスワードの強度を高くする必要があります')
          } else if (err.code === 'auth/requires-recent-login') {
            // ログイン状態が長く続いているため、再ログインする必要があるときに発生するエラー

            openModal(
              <RequireReLoginModal router={router} onClose={closeModal} />,
              {
                persistent: true,
              }
            )
          } else {
            // 予期しないエラー

            showNotification(
              notificationTypes.ERROR,
              'エラーが発生しました',
              `パスワードの変更中に予期しないエラーが発生しました。${err.message}`
            )
          }
        })
        .finally(() => {
          setIsSending(false)
        })
    },
    [closeModal, isValid, openModal, password, router, showNotification]
  )

  return (
    <AppLayout title="設定">
      <Container>
        <Section>
          <SectionTitle>パスワードを変更</SectionTitle>

          <StyledValidatableForm
            onValidated={isValid => setIsValid(isValid)}
            onSubmit={onPasswordSubmit}
          >
            <TextField
              label="新しいパスワード"
              type="password"
              value={password}
              onChange={onPasswordChange}
              rules={passwordRules}
              background={colors.gray[100]}
              error={passwordError}
              lazyVaridate
            />

            <ButtonsWrapper>
              <RaisedButton type="submit" loading={isSending} long>
                変更
              </RaisedButton>
            </ButtonsWrapper>
          </StyledValidatableForm>
        </Section>
      </Container>
    </AppLayout>
  )
}

export default Settings

const Container = styled.div`
  width: 100%;
  padding: 0 2rem 2rem 0;
`

const Section = styled.div`
  padding: 2rem;
  border-radius: 1rem;
  background: ${colors.gray[100]};
`

const SectionTitle = styled.h2`
  color: ${colors.text.dark};
  font-size: 1.5rem;
  font-weight: bold;
`

const StyledValidatableForm = styled(ValidatableForm)`
  margin-top: 2rem;
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 2rem;
`
