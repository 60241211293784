import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { addMember } from 'redux/actions/work-actions'

import { colors } from 'consts/theme'

import ValidatableForm from 'components/common/atoms/ValidatableForm'
import RaisedButton from 'components/common/atoms/RaisedButton'
import FlatButton from 'components/common/atoms/FlatButton'
import Dropdown, { dropdownTypes } from 'components/common/atoms/Dropdown'

const dropdownRules = [
  v => {
    if (v !== '') return true
    return '追加する従業員を選択してください'
  },
]

const AddWorkMemberModal = ({ onClose }) => {
  const [input, setInput] = useState('')
  const [isValid, setIsValid] = useState(true)
  const members = useSelector(state => state.work.members)
  const work = useSelector(state => state.work.work)
  const dispatch = useDispatch()

  /**
   * 従業員を追加する
   * @param {Event} e
   */
  const onSubmit = async e => {
    e.preventDefault()

    if (!isValid) return

    dispatch(addMember(input))

    onClose()
  }

  /**
   * Variables
   */
  const filteredMemberIds = useMemo(
    () =>
      Object.keys(members)
        .filter(id => !Object.keys(work?.members ?? {}).includes(id))
        .sort((a, b) => {
          if (members[a].companyShort < members[b].companyShort) {
            return -1
          } else if (members[a].companyShort > members[b].companyShort) {
            return 1
          } else if (members[a].name < members[b].name) {
            return -1
          } else if (members[a].name > members[b].name) {
            return 1
          }
          return 0
        }),
    [members, work]
  )

  const memberIds = ['', ...filteredMemberIds]

  const memberNames = [
    '選択してください',
    ...filteredMemberIds.map(id => `${members[id].name}`),
  ]

  return (
    <Form onValidated={e => setIsValid(e)} onSubmit={onSubmit}>
      <Title>従業員を追加</Title>
      <Description>
        場割りされていない従業員の日報を報告するために、従業員を追加します。
      </Description>

      <StyledDropdown
        selected={input}
        values={memberIds}
        items={memberNames}
        type={dropdownTypes.INPUT_LIKE}
        rules={dropdownRules}
        onChange={e => setInput(e)}
        lazyVaridate
      />

      <Trailing>
        <FlatButton onClick={onClose}>キャンセル</FlatButton>
        <RaisedButton
          type="submit"
          color={colors.primary[500]}
          disabled={!isValid}
          long
        >
          追加
        </RaisedButton>
      </Trailing>
    </Form>
  )
}

AddWorkMemberModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default AddWorkMemberModal

const Form = styled(ValidatableForm)`
  min-width: 30rem;
`

const Title = styled.h1`
  color: ${colors.text.dark};
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: bold;
`

const Description = styled.p`
  margin-top: 1rem;
  color: ${colors.text.base};
`

const StyledDropdown = styled(Dropdown)`
  margin-top: 2rem;
`

const Trailing = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 2rem;

  > * {
    margin-left: 1rem;
  }
`
