import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import useAsync from 'hooks/useAsync'
import useModal from 'hooks/useModal'
import useNotification, { notificationTypes } from 'hooks/useNotification'
import DataTableTypes from 'consts/data-table-types'
import { getSites, getClients } from 'utils/api'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppLayout from 'layouts/AppLayout'
import AddSiteModal from 'components/sites/molecules/AddSiteModal'
import EditSiteModal from 'components/sites/molecules/EditSiteModal'
import DeleteSiteModal from 'components/sites/molecules/DeleteSiteModal'
import RecoverSiteModal from 'components/sites/molecules/RecoverSiteModal'
import DataTable from 'components/common/molecules/DataTable'
import RaisedButton from 'components/common/atoms/RaisedButton'
import ToggleButton from 'components/common/atoms/ToggleButton'
import IconButton from 'components/common/atoms/IconButton'
import SearchBox from 'components/common/atoms/SearchBox'
import Dropdown from 'components/common/atoms/Dropdown'

const columns = [
  '現場名',
  '略称',
  'クライアント',
  '現場住所',
  '開始日',
  '終了日',
  '',
]
const keys = [
  'name',
  'shortName',
  'clientName',
  'address',
  'from',
  'to',
  'actions',
]
const types = [
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.TEXT,
  DataTableTypes.NODE_RIGHT,
]
const widths = ['3fr', '1fr', '2fr', '3fr', '2fr', '2fr', '128px']

const periodDropdownValues = [
  'all',
  'notFinished',
  'beforePeriod',
  'inPeriod',
  'finished',
]
const periodDropdownItems = ['すべて', '未完了', '工期前', '工期中', '完了済み']

const Sites = () => {
  const [isFilterShown, setIsFilterShown] = useState(false)
  const [filters, setFilters] = useState({ name: '', period: 'all' })
  const {
    pending: isSitesPending,
    value,
    error: sitesError,
    execute: loadAgain,
  } = useAsync(getSites)
  const {
    pending: isClientsPending,
    value: clients,
    error: clientsError,
  } = useAsync(getClients)
  const [openModal, closeModal] = useModal()
  const showNotification = useNotification()

  // Site配列をオブジェクトに変換
  const sites = useMemo(() => {
    const sites = {}
    for (const site of value ?? []) {
      sites[site.id] = site
    }
    return sites
  }, [value])

  // エラーハンドリング
  useEffect(() => {
    if (sitesError == null) {
      return
    }

    showNotification(
      notificationTypes.ERROR,
      'エラーが発生しました',
      `現場データの取得中にエラーが発生しました。時間をおいて、再度お試しください。${sitesError.message}`
    )

    console.error(sitesError)
  }, [sitesError, showNotification])
  useEffect(() => {
    if (clientsError == null) {
      return
    }

    showNotification(
      notificationTypes.ERROR,
      'エラーが発生しました',
      `取引先企業データの取得中にエラーが発生しました。時間をおいて、再度お試しください。${clientsError.message}`
    )

    console.error(clientsError)
  }, [clientsError, showNotification])

  const onAddClicked = useCallback(() => {
    if (isClientsPending) return

    openModal(
      <AddSiteModal clients={clients} onAdd={loadAgain} onClose={closeModal} />,
      {
        persistent: true,
      }
    )
  }, [clients, closeModal, isClientsPending, loadAgain, openModal])

  const onEditClicked = useCallback(
    id => {
      if (isClientsPending) return

      openModal(
        <EditSiteModal
          clients={clients}
          site={sites[id]}
          onEdit={loadAgain}
          onClose={closeModal}
        />,
        { persistent: true }
      )
    },
    [clients, closeModal, isClientsPending, loadAgain, openModal, sites]
  )

  const onDeleteClicked = useCallback(
    id => {
      openModal(
        <DeleteSiteModal
          site={sites[id]}
          onDelete={loadAgain}
          onClose={closeModal}
        />
      )
    },
    [closeModal, loadAgain, openModal, sites]
  )

  const onRecoverClicked = useCallback(
    id => {
      openModal(
        <RecoverSiteModal
          site={sites[id]}
          onRecover={loadAgain}
          onClose={closeModal}
        />,
        { persistent: true }
      )
    },
    [closeModal, loadAgain, openModal, sites]
  )

  // 表に表示するデータに整形
  const data = useMemo(() => {
    const today = moment()

    return (
      Object.values(sites ?? {})
        .filter(v => {
          // 現場名フィルタ
          if (
            !v.name.includes(filters.name) &&
            !v.shortName.includes(filters.name)
          ) {
            return false
          }

          // 工期フィルタ
          if (
            filters.period === 'notFinished' &&
            today.isAfter(v.workPeriod.to)
          ) {
            return false
          } else if (
            filters.period === 'beforePeriod' &&
            today.isSameOrAfter(v.workPeriod.from)
          ) {
            return false
          } else if (
            filters.period === 'inPeriod' &&
            (today.isBefore(v.workPeriod.from) ||
              today.isAfter(v.workPeriod.to))
          ) {
            return false
          } else if (
            filters.period === 'finished' &&
            today.isSameOrBefore(v.workPeriod.to)
          ) {
            return false
          }

          return true
        })
        .sort((a, b) => {
          if (a.clientName < b.clientName) {
            return -1
          } else if (a.clientName > b.clientName) {
            return 1
          } else if (a.name < b.name) {
            return -1
          } else if (a.name > b.name) {
            return 1
          }
          return 0
        })
        .map(v => {
          return {
            id: v.id,
            name: v.name,
            shortName: v.shortName,
            clientName: v.clientName,
            address: v.address,
            from: v.workPeriod.from.format('YYYY-MM-DD'),
            to: v.workPeriod.to.format('YYYY-MM-DD'),
            actions: [
              <IconButton
                onClick={() => {
                  onEditClicked(v.id)
                }}
                disabled={isClientsPending}
                key="edit"
              >
                <FontAwesomeIcon icon={['far', 'pen']} />
              </IconButton>,
              <IconButton
                onClick={() => {
                  onDeleteClicked(v.id)
                }}
                key="delete"
              >
                <FontAwesomeIcon icon={['far', 'trash']} />
              </IconButton>,
              <IconButton
                onClick={() => {
                  onRecoverClicked(v.id)
                }}
                key="recover"
              >
                <FontAwesomeIcon icon={['far', 'tools']} />
              </IconButton>,
            ],
          }
        }) ?? []
    )
  }, [
    filters.name,
    filters.period,
    isClientsPending,
    onDeleteClicked,
    onEditClicked,
    onRecoverClicked,
    sites,
  ])

  return (
    <AppLayout title="現場">
      <Container>
        <ActionWrapper>
          <ToggleButton
            icon={<FontAwesomeIcon icon={['far', 'sliders-v']} />}
            value={isFilterShown}
            onChange={e => setIsFilterShown(e)}
          >
            フィルター
          </ToggleButton>
          <RaisedButton onClick={onAddClicked} loading={isClientsPending} long>
            追加
          </RaisedButton>
        </ActionWrapper>

        <FilterWrapper shown={isFilterShown}>
          <SearchBox
            placeholder="現場名で絞り込む"
            value={filters.name}
            onChange={e => setFilters({ ...filters, name: e.target.value })}
          />
          <StyledDropdown
            selected={filters.period}
            values={periodDropdownValues}
            items={periodDropdownItems}
            onChange={e => setFilters({ ...filters, period: e })}
          />
        </FilterWrapper>

        <StyledDataTable
          columns={columns}
          keys={keys}
          data={data}
          types={types}
          widths={widths}
          isLoading={isSitesPending}
        />
      </Container>
    </AppLayout>
  )
}

export default Sites

const Container = styled.div`
  width: 100%;
  padding: 0 2rem 2rem 0;
`

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FilterWrapper = styled.div`
  display: ${p => (p.shown ? 'flex' : 'none')};
  align-items: center;

  margin-top: 2rem;
`

const StyledDropdown = styled(Dropdown)`
  margin-left: 1rem;
`

const StyledDataTable = styled(DataTable)`
  margin-top: 2rem;
`
