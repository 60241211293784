export const colors = {
  text: {
    light: '#718096',
    base: '#4A5568',
    dark: '#2D3748',
  },
  primary: {
    '100': '#EBF7E9',
    '200': '#CCECC8',
    '300': '#ADE0A7',
    '400': '#70C966',
    '500': '#32B224',
    '600': '#2DA020',
    '700': '#1E6B16',
    '800': '#175010',
    '900': '#0F350B',
  },
  error: {
    '100': '#FFF5F5',
    '200': '#FED7D7',
    '300': '#FEB2B2',
    '400': '#FC8181',
    '500': '#F56565',
    '600': '#E53E3E',
    '700': '#C53030',
    '800': '#9B2C2C',
    '900': '#742A2A',
  },
  gray: {
    '100': '#F7FAFC',
    '200': '#EDF2F7',
    '300': '#E2E8F0',
    '400': '#CBD5E0',
    '500': '#A0AEC0',
    '600': '#718096',
    '700': '#4A5568',
    '800': '#2D3748',
    '900': '#1A202C',
  },
  red: {
    '100': '#FFF5F5',
    '200': '#FED7D7',
    '300': '#FEB2B2',
    '400': '#FC8181',
    '500': '#F56565',
    '600': '#E53E3E',
    '700': '#C53030',
    '800': '#9B2C2C',
    '900': '#742A2A',
  },
  blue: {
    '100': '#EBF8FF',
    '200': '#BEE3F8',
    '300': '#90CDF4',
    '400': '#63B3ED',
    '500': '#4299E1',
    '600': '#3182CE',
    '700': '#2B6CB0',
    '800': '#2C5282',
    '900': '#2A4365',
  },
}

export const shadows = {
  xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
  sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  rg: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  xl:
    '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
}
