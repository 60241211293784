import React from 'react'
import styled from 'styled-components'
import { withScope, captureException } from '@sentry/browser'

import { colors } from 'consts/theme'

import RaisedButton from 'components/common/atoms/RaisedButton'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Card>
            <Title>エラーが発生しました</Title>
            <Description>
              申し訳ございません。アプリケーションの実行中にエラーが発生しました。
              <br />
              アプリケーションの実行を継続することができなくなったため、初めからやり直してください。
            </Description>
            <Description>このエラーは自動的にレポートされます。</Description>
            <RaisedButton to="/login">アプリに戻る</RaisedButton>
          </Card>
        </Container>
      )
    }

    return this.props.children
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
  padding: 2rem;
`

const Card = styled.div`
  padding: 2rem;
  border: 1px solid ${colors.gray[300]};
  border-radius: 1rem;

  text-align: center;
`

const Title = styled.h1`
  color: ${colors.text.dark};
  font-size: 1.5rem;
  font-weight: bold;
`

const Description = styled.p`
  margin: 1rem 0;

  color: ${colors.text.light};
  font-size: 1rem;
`
